import React, { useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetInfo } from '../../actions/auth.action'
import { ToastContainer, toast } from 'react-toastify'
import './ForgotInfo.css'

const ForgotInfo = (props) => {
  const dispatch = useDispatch()
  const { resForgotPass } = useSelector((state) => state.auth)

  const [formData, setFormData] = useState({
    username: '',
    accountname: '',
    phone: '',
  })

  const submit = () => {
    dispatch(resetInfo(formData.username, formData.accountname, formData.phone))
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const onSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  return (
    <Fragment>
      {resForgotPass && resForgotPass.status === 'success' ? (
        <div className="App">
          <div className="form-membership">
            <ToastContainer autoClose={5000} />
            {onSuccess(resForgotPass.data)}
            <div className="form-wrapper">
              <p className="form-info">
                An email has been sent to the address you provided. Please check that email for temporary credentials
                that you can use to access your account. Once signed in, you will need to change/update your password.
              </p>
              <div className="infobox">
                <a className="link" href="/">
                  Sign-in
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="App">
          <div className="form-membership">
            <ToastContainer autoClose={5000} />
            <div className="form-wrapper">
              <p className="form-info">In order to recover your password, please enter your email/username:</p>
              <div className="form-group input-group-lg">
                <label>Email/Username</label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  onChange={handleChange}
                  required
                  autoFocus
                />
              </div>
              <label className="msg">And one of the following (only one is required):</label>
              <div className="form-group input-group-lg">
                <label>Account Name</label>
                <input type="text" name="accountname" className="form-control" onChange={handleChange} required />
              </div>
              <div className="form-group input-group-lg ">
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  onKeyPress={handleChange}
                  onChange={handleChange}
                  required
                />
              </div>
              <button className="btn btn-primary btn-lg btn-block" onClick={submit}>
                Reset Password
              </button>
              <div className="infobox">
                <span className="info">Remember your password?</span>
                <a className="link" href="/">
                  Sign-in
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ForgotInfo
