import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { confirmAlert } from 'react-confirm-alert'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import Sidebar from '../Sidebar/Sidebar'
import { getCallForward } from '../../actions/message.action'
import { getDistributionList } from '../../actions/distribution.action'
import {
  saveNewAutomation,
  editAutomationData,
  getAutomations,
  deleteAutomation,
  getPreFlow,
  saveCallFlowData,
} from '../../actions/automation.action'
import Dialog from '../Sidebar/Dialog'
import UserHeader from '../Header/UserHeader'
import './styles.css'

const Automation = (props) => {
  const dispatch = useDispatch()
  const { styleMode, numberList } = useSelector((state) => state.message.numbers)
  const { userName } = useSelector((state) => state.message)
  const { allData, preFlow, saveFlow } = useSelector((state) => state.automation)
  const distributionLists = useSelector((state) => state.distribution.allList)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [preCallFlow, setPreCallFlow] = useState({})
  const [settingPanelToggle, updateSettingPanel] = useState(false)
  const [distributionOptions, setDistributionOptions] = useState([])
  const [automationValue, setAutomationValue] = useState({
    name: '',
    number: null,
    type: null,
    distribution: null,
    phrase: '',
    response: '',
    acitve: true,
  })
  const [distributionToogle, setDistributionToogle] = useState(false)
  const [automationData, setAutomationData] = useState([])
  const [selectNumberOptions, setSelectNumberOptions] = useState([])
  const selectTypeOptions = [
    { value: 'Missed Call', label: 'Missed Call' },
    { value: 'Keyword Auto Responder', label: 'Keyword Auto Responder' },
  ]
  const [editAutomationID, setEditAutomationID] = useState('')
  const [flagAutomation, setFlagAutomation] = useState('')
  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const automationModal = () => {
    setFlagAutomation('add')
    setEditAutomationID('')
    setAutomationValue({
      name: '',
      number: '',
      type: '',
      phrase: '',
      distribution: '',
      response: '',
      acitve: true,
    })
    updateSettingPanel(!settingPanelToggle)
  }
  const changeInputValue = (e) => {
    setAutomationValue({ ...automationValue, [e.target.id]: e.target.value })
  }
  const changeActive = () => {
    setAutomationValue({ ...automationValue, acitve: !automationValue.acitve })
  }
  const changeNumber = (value) => {
    setAutomationValue({ ...automationValue, number: value })
  }
  const changeType = (value) => {
    setAutomationValue({ ...automationValue, type: value })
  }
  const changeDistribution = (value) => {
    setAutomationValue({ ...automationValue, distribution: value })
  }
  const saveAutomation = () => {
    if (automationValue.name === '') return
    if (automationValue.number.value === undefined) return
    if (automationValue.response === '') return
    const owner_id = localStorage.getItem('user_id')
    const data = {
      name: automationValue.name,
      phone_number: automationValue.number.value,
      type: automationValue.type.value,
      phrase: automationValue.phrase,
      response: automationValue.response,
      status: automationValue.acitve,
      distribution_id: automationValue.distribution.value,
      user_email: owner_id,
      user_name: userName.fullName,
    }
    dispatch(saveNewAutomation(data))
    automationModal()
  }
  const editAutomation = () => {
    if (automationValue.name === '') return
    if (automationValue.number.value === undefined) return
    if (automationValue.response === '') return
    const data = {
      name: automationValue.name,
      phone_number: automationValue.number.value,
      type: automationValue.type.value,
      phrase: automationValue.phrase,
      response: automationValue.response,
      status: automationValue.acitve,
      user_email: userName.userEmail,
      user_name: userName.fullName,
      distribution: automationValue.distribution,
    }
    dispatch(editAutomationData(editAutomationID, data))
    automationModal()
  }

  const actionAutomation = (data) => {
    setFlagAutomation('edit')
    setEditAutomationID(data._id)
    const distributionValue = distributionOptions.find((distribution) => distribution.value === data.distribution_id)
    setAutomationValue({
      name: data.name,
      number: { value: data.phone_number, label: data.phone_number },
      type: { value: data.type, label: data.type },
      phrase: data.phrase,
      response: data.response,
      acitve: data.status,
      distribution: distributionValue,
    })
    updateSettingPanel(true)
  }
  const deleteData = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this file?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                dispatch(deleteAutomation(id))
                onClose()
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        )
      },
    })
  }

  const missedCallAlert = () => {
    dispatch(saveCallFlowData())
  }
  useEffect(() => {
    if (numberList && numberList.length > 0) {
      const numberArr = []
      numberList.forEach((number) => {
        numberArr.push({ value: number.number, label: number.number })
      })
      setSelectNumberOptions(numberArr)
      dispatch(getAutomations(numberList))
    }
    // eslint-disable-next-line
  }, [numberList])

  useEffect(() => {
    setAutomationData(allData)
  }, [allData])

  useEffect(() => {
    setPreCallFlow(preFlow)
  }, [preFlow])

  useEffect(() => {
    if (Object.keys(saveFlow).length !== 0) {
      if (saveFlow.state) {
        toast.success(saveFlow.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.warn(saveFlow.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }, [saveFlow])

  useEffect(() => {
    let options = []
    for (const list of distributionLists) {
      options.push({
        value: list._id,
        label: list.name,
      })
    }
    setDistributionOptions(options)
  }, [distributionLists])

  useEffect(() => {
    dispatch(getPreFlow())
    dispatch(getDistributionList())

    // eslint-disable-next-line
  }, [])

  return (
    <div className={styleMode}>
      <div className="layout-user">
        <ToastContainer autoClose={8000} />
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} />
          <div className="automation">
            <Button color="primary" onClick={automationModal}>
              + New Automation
            </Button>
            {Object.keys(preCallFlow).length === 0 && (
              <Button color="primary" className="ml-3" onClick={missedCallAlert}>
                Run Missed Call Alert Backend Setup
              </Button>
            )}

            <table className="table table-striped mb-0 client-table mt-4">
              <thead>
                <tr>
                  <th>Automation Name</th>
                  <th>Phone Number</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Keyword/Phrase</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {automationData.length > 0 &&
                  automationData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.name}</td>
                      <td>{data.phone_number}</td>
                      <td>{data.type}</td>
                      <td>{data.status === true ? 'Active' : 'Inactive'}</td>
                      <td>{data.phrase}</td>
                      <td>
                        <span className="mr-3">
                          <i className="ti-settings" onClick={() => actionAutomation(data)}></i>
                        </span>
                        <span>
                          <i className="ti-trash" onClick={() => deleteData(data._id)}></i>
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Modal
            isOpen={settingPanelToggle}
            toggle={automationModal}
            className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
          >
            <ModalHeader toggle={automationModal}>
              <i className="fas fa-robot"></i> {flagAutomation === 'add' ? ' Add New Automation' : 'Edit Automation'}
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={automationValue.name}
                    onChange={changeInputValue}
                  />
                </div>
                <div className="form-item custom-control custom-switch mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="active"
                    checked={automationValue.acitve === true}
                    onChange={changeActive}
                  />
                  <label className="custom-control-label" htmlFor="active">
                    Active / Inactive
                  </label>
                </div>
                <div className="mb-3">
                  <Select
                    isClearable
                    value={automationValue.type}
                    onChange={changeType}
                    options={selectTypeOptions}
                    placeholder="Automation Type"
                    maxMenuHeight={200}
                  />
                </div>
                <div className="mb-3">
                  <Select
                    isClearable
                    value={automationValue.number}
                    onChange={changeNumber}
                    options={selectNumberOptions}
                    placeholder="Select Number"
                    maxMenuHeight={200}
                  />
                </div>
                <div className="mb-3">
                  <div className="form-item custom-control custom-switch mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="distributionToggle"
                      checked={distributionToogle}
                      onChange={() => setDistributionToogle(!distributionToogle)}
                      disabled={automationValue?.type?.label !== 'Keyword Auto Responder'}
                    />
                    <label className="custom-control-label" htmlFor="distributionToggle">
                      Add to a Distribution List
                    </label>
                  </div>
                  <Select
                    isClearable
                    onChange={changeDistribution}
                    isDisabled={!distributionToogle}
                    value={automationValue.distribution}
                    options={distributionOptions}
                    placeholder="Distribution List"
                    maxMenuHeight={200}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="phrase"
                    placeholder="Keyword / Phrase"
                    value={automationValue.phrase}
                    onChange={changeInputValue}
                    disabled={automationValue.type && automationValue.type.label === 'Missed Call'}
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="response"
                    value={automationValue.response}
                    placeholder="Response (160 characters)"
                    onChange={changeInputValue}
                    maxLength="160"
                  ></textarea>
                </div>
                <div className="text-right">
                  {flagAutomation === 'add' ? (
                    <Button color="primary" onClick={saveAutomation}>
                      Save
                    </Button>
                  ) : (
                    <Button color="primary" onClick={editAutomation}>
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Automation
