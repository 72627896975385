import React from 'react'
import { BallBeat } from 'react-pure-loaders'

export default class Loader extends React.Component {
  render() {
    let loader = this.props.loader
    return (
      <div>
        <BallBeat color={'#123abc'} loading={loader} />
      </div>
    )
  }
}
