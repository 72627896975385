import axios from 'axios'
import CONFIG from '../constants/config'
import * as CONSTS from '../constants/const'

axios.defaults.headers.get['Content-Type'] = 'application/json'

export const getCallLogs = (startDate, endDate, users) => {
  return async (dispatch) => {
    let start_timestamp = startDate + 62167219200
    let end_timestamp = endDate + 62167219200
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    if (users.length > 0) {
      const callLogs = []
      for (let i = 0; i < users.length; i++) {
        let user = users[i]
        const URI = `${CONFIG.API_URL}/accounts/${account_id}/users/${user.userId}/cdrs/interaction?created_from=${start_timestamp}&created_to=${end_timestamp}&paginate=false`
        let calllog = await axios.get(URI)
        callLogs.push(calllog.data.data)
      }
      dispatch({ type: CONSTS.GET_CALL_REPORTS, payload: callLogs })
    }
  }
}
