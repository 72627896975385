import React, { useCallback, useState } from 'react'
import { Button, Input, ModalFooter } from 'reactstrap'
import CampaignAttribute from './campaign-attribute'
import CampaignSampleMessage from './campaign-sample-message'
import ResponsibleParties from './responsible-parties'
import './common-style.css'

const CampaignCreateSection = ({ onBack, brand, usecase, onSubmit }) => {
  const [form, setForm] = useState({
    description: '',
    messageFlow: '',
    sample1: '',
    sample2: '',
    sample3: '',
    sample4: '',
    sample5: '',
    embeddedLink: null,
    embeddedPhone: null,
    affiliateMarketing: false,
    termsAndConditions: null,
    ageGated: null,
    directLending: null,
    subscriberOptin: null,
    subscriberOptout: null,
    subscriberHelp: null,
    helpKeywords: '',
    helpMessage: '',
    resellerId: '',
  })

  const handleChange = useCallback((e) => {
    e.persist()
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }, [])

  const handleSampleMessageChange = useCallback((messages) => {
    setForm((prev) => ({
      ...prev,
      sample1: '',
      sample2: '',
      sample3: '',
      sample4: '',
      sample5: '',
      ...messages.reduce((prev, curr, index) => {
        prev[`sample${index + 1}`] = curr?.content || ''
        return prev
      }, {}),
    }))
  }, [])

  const handleAttributeChange = useCallback((field, value) => {
    setForm((prev) => ({ ...prev, [field]: value }))
  },[])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit(form)
    },
    [form, onSubmit],
  )

  return (
    <form
      onSubmit={handleSubmit}
      className="campaign-details-form"
      style={{ backgroundColor: 'white', borderRadius: '27px' }}
    >
      <div className="use-start-case">
        <p className="title-of-form">Campaign Details</p>
      </div>

      <div className="wrapper-preview">
        <p className="title-of-form"> Brand</p>
        <Input placeholder="Brand Name." disabled value={brand?.displayName} />
      </div>
      <div className="wrapper-preview">
        <p className="title-of-form">Campaign Details</p>

        <div className="col-6 ">
          <Input type="text" style={{ margin: '10px' }} placeholder="Use Case" disabled value={usecase?.displayName} />
        </div>
        <div className="col-6 ">
          <textarea
            className="form-control"
            style={{ margin: '10px' }}
            type="text"
            onChange={handleChange}
            name="description"
            value={form.description}
            placeholder="Campaign Description"
          />
        </div>
        <div className="col-6 ">
          <textarea
            className="form-control"
            style={{ margin: '10px' }}
            type="text"
            onChange={handleChange}
            name="messageFlow"
            value={form.messageFlow}
            placeholder="Call-to-Action / Message Flow"
          />
        </div>
      </div>
      <div className="wrapper-preview">
        <CampaignSampleMessage onChange={handleSampleMessageChange} />
      </div>
      <div className="wrapper-preview">
        <p className="title-of-form">
          Campaign and Content Attributes (For M2M (Machine-to-Machine) Use Case, please select No if the attributes are
          not applicable.)
        </p>
        <div className="row">
          <div className="col-6 test-data">
            <CampaignAttribute
              label="Subscriber Opt-in"
              type="opt-in"
              booleanField="subscriberOptin"
              form={form}
              onChange={handleAttributeChange}
              keywordField="optinKeywords"
              messageField="optinMessage"
              disabledNo
            />
            <CampaignAttribute
              label="Subscriber Opt-out"
              type="opt-out"
              booleanField="subscriberOptout"
              form={form}
              onChange={handleAttributeChange}
              keywordField="optoutKeywords"
              messageField="optoutMessage"
            />
            <CampaignAttribute
              label="Subscriber Help"
              type="help"
              booleanField="subscriberHelp"
              form={form}
              onChange={handleAttributeChange}
              keywordField="helpKeywords"
              messageField="helpMessage"
              disabledNo
            />
            <CampaignAttribute
              label="Number Pooling"
              canExpand={false}
              booleanField="numberPool"
              form={form}
              onChange={handleAttributeChange}
            />
            <CampaignAttribute
              label="Direct Lending or Loan Arrangment"
              canExpand={false}
              booleanField="directLending"
              form={form}
              onChange={handleAttributeChange}
            />
          </div>
          <div className="col-6">
            <CampaignAttribute
              label="Embedded Link"
              canExpand={false}
              booleanField="embeddedLink"
              form={form}
              onChange={handleAttributeChange}
            />
            <CampaignAttribute
              label="Embedded Phone Number"
              canExpand={false}
              booleanField="embeddedPhone"
              form={form}
              onChange={handleAttributeChange}
            />
            <CampaignAttribute
              label="Age-Gated Content"
              canExpand={false}
              booleanField="ageGated"
              form={form}
              onChange={handleAttributeChange}
            />
            <CampaignAttribute
              label="Terms & Conditions"
              canExpand={false}
              booleanField="termsAndConditions"
              form={form}
              onChange={handleAttributeChange}
              disabledNo
            />
          </div>
        </div>
      </div>
      <ResponsibleParties
        resellerId={form.resellerId}
        onChange={(value) => handleAttributeChange('resellerId', value)}
      />
      <ModalFooter style={{ gap: 8, display: 'flex' }}>
        <Button style={{ backgroundColor: 'green', color: 'white' }}  type="button" onClick={onBack}>
          Back
        </Button>
        <Button type="submit" color="primary">
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

export default CampaignCreateSection
