import * as CONSTS from '../constants/const'

let defaultState = {
  userNumbers: [],
  userData: [],
  userPresenceState: {},
  userCallforward: {},
  userFollowDevices: [],
  userDevices: [],
  userDevice: {},
  userCallFlowID: '',
  userFlowData: {},
  userDirectories: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.USER_NUMBERS:
      return { ...state, userNumbers: action.payload }
    case CONSTS.GET_USERS:
      return { ...state, userData: action.payload }
    case CONSTS.RESET_PRESENCE_STATUS:
      return { ...state, userPresenceState: action.payload }
    case CONSTS.USER_CALL_FORWARD:
      return { ...state, userCallforward: action.payload }
    case CONSTS.USER_FOLLOW_DEVICES:
      return { ...state, userFollowDevices: action.payload }
    case CONSTS.USER_DEVICES:
      return { ...state, userDevices: action.payload }
    case CONSTS.USER_DEVICES_NAME:
      return { ...state, userDevice: action.payload }
    case CONSTS.USER_FOLLOW_ID:
      return { ...state, userCallFlowID: action.payload }
    case CONSTS.USER_FOLLOW_DATA:
      return { ...state, userFlowData: action.payload }
    case CONSTS.USER_DIRECTORIES:
      return { ...state, userDirectories: action.payload }

    default:
      return state
  }
}
