import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap'
import { getDistributionList, addNewContact, deleteContact, editContact } from '../../actions/distribution.action'
import { useDispatch, useSelector } from 'react-redux'
import './contact-detail.css'
import DistrutionIcons from '../../asset/media/img/distrution.png'

const DistributionMenuModel = (props) => {
  const { allList, distributionContacts } = useSelector((state) => state.distribution)
  const [listLengthData, setListLengthData] = useState(0)
  const [distributionMenuModel, setDistributionMenuModel] = useState(false)
  const [showMoreData, setShowMoredata] = useState(false)
  const [showApplyData, setShowApplyData] = useState(false)
  const [applyData] = useState([])
  const { disName } = props
  const limitData = 6
  const dispatch = useDispatch()
  const [selected, setSelected] = useState({})

  useEffect(() => {
    setSelected(
      (disName || []).reduce((prev, curr) => {
        prev[curr._id] = true
        return prev
      }, {}),
    )
  }, [disName])

  const handleDistributionModel = () => {
    setDistributionMenuModel((prevState) => !prevState)
    setShowApplyData(false)
    setShowMoredata(false)
    setListLengthData(0)
  }

  useEffect(() => {
    dispatch(getDistributionList())
    // eslint-disable-next-line
  }, [])

  const handleMore = () => {
    setListLengthData(allList.length - limitData)
    setShowMoredata(true)
  }

  const handleContributionApply = () => {
    const output = []
    const distData = []
    if (distributionContacts) {
      distributionContacts.distribution &&
        distributionContacts.distribution.forEach((dis) => {
          allList &&
            allList.forEach((data) => {
              if (data._id === dis.distributionId) {
                output.push({ ...data, refId: dis._id })
              }
            })
        })
    }
    const filtered = (allList || []).filter((el) => selected[el._id])
    props.setdisName(filtered)
    const newContact = props.contactsDetail
    const distArr = distributionContacts.distribution
    if (distArr) {
      filtered.filter((prop) => {
        distArr.map((item) => {
          if (item.distributionId === prop._id && item.phoneNumber === newContact.phoneNumber) {
            applyData.push(item.distributionId)
            distData.push(item)
          }
          return null
        })
        return null
      })
    }
    for (let distribution of filtered) {
      if (!applyData.includes(distribution._id)) {
        const contact = {
          name: newContact.labelName,
          phoneNumber: newContact.phoneNumber,
          status: true,
          distributionId: distribution._id,
        }
        dispatch(addNewContact(contact))
      } else {
        distData.map((element) => {
          if (element.distributionId === distribution._id && element.status === false) {
            const id = element._id
            const updatedContact = {
              distributionId: distribution._id,
              phoneNumber: newContact.phoneNumber,
              status: true,
              name: newContact.labelName,
            }
            dispatch(editContact(id, updatedContact))
          }
          return null
        })
      }
    }
    output
      .filter((x) => selected[x._id] === false)
      .map((x) => dispatch(deleteContact(x.refId, x.distributionId, false)))
    setDistributionMenuModel(false)
  }

  return (
    <div className="distribution-menu">
      <Dropdown isOpen={distributionMenuModel} toggle={handleDistributionModel}>
        <DropdownToggle className="distribution-icon">
          <div>
            <img className="iconsSet" src={DistrutionIcons} alt="avatar" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="distribution-dropmenu">
          <div className="drop-scroll">
            {allList &&
              allList.map(
                (distData, index) =>
                  index < limitData + listLengthData && (
                    <DropdownItem toggle={false} key={index} className="drop-distributionData">
                      <input
                        type="checkbox"
                        className="dist-badge-data checkboxstyle"
                        checked={!!selected[distData._id]}
                        onClick={() => setSelected((prev) => ({ ...prev, [distData._id]: !prev[distData._id] }))}
                      />
                      <Badge style={{ backgroundColor: `${distData.color}` }} className="dist-badge-data"></Badge>
                      {distData.name}
                    </DropdownItem>
                  ),
              )}
          </div>
          {!showMoreData && allList.length > limitData && (
            <p onClick={handleMore} className="dist-more">
              {allList.length - limitData} more
            </p>
          )}
          {!showApplyData ? (
            <div className="btn position-add-dropdown" onClick={handleContributionApply}>
              Apply
            </div>
          ) : (
            ''
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default DistributionMenuModel
