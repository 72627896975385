import axios from 'axios'
import CONFIG from '../constants/config'
import * as CONSTS from '../constants/const'

axios.defaults.headers.get['Content-Type'] = 'application/json'

export const getFaxesDetails = () => {
  return (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const inbox = `${CONFIG.API_URL}/accounts/${account_id}/faxes/inbox?paginate=false`
    const faxbox_number = `${CONFIG.API_URL}/accounts/${account_id}/faxboxes`

    axios.all([axios.get(inbox), axios.get(faxbox_number)]).then(
      axios.spread(async (inbox, faxbox_number) => {
        const userFaxDetails =
          faxbox_number.data.data && faxbox_number.data.data.find((faxbox) => faxbox.owner_id === user_id)

        const mainFaxDetails =
          faxbox_number.data.data && faxbox_number.data.data.find((faxbox) => faxbox.owner_id === account_id)

        const myFaxNumber = userFaxDetails && userFaxDetails.caller_id
        const defaultFaxNumber = mainFaxDetails && mainFaxDetails.caller_id
        localStorage.setItem('myFaxNumber', myFaxNumber)
        localStorage.setItem('defaultFaxNumber', defaultFaxNumber)

        const inboxArray = []
        inbox.data.data &&
          inbox.data.data.forEach((inbox) => {
            if (inbox.to === myFaxNumber || inbox.to === defaultFaxNumber) {
              inboxArray.push(inbox)
            }
          })

        await dispatch({ type: CONSTS.GET_ALL_INBOX_FAXES, payload: inboxArray })
        await dispatch({ type: CONSTS.FAXBOX_NUMBERS, payload: faxbox_number.data.data })
      }),
    )
  }
}

export const sendFax = (data, numbers) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/sendfax`, data)
      .then(async (res) => {
        await dispatch(getFaxesDetails())
        await dispatch(getSentFaxes(numbers))
      })
      .catch(async (error) => {
        await dispatch(getFaxesDetails())
        await dispatch(getSentFaxes(numbers))
      })
  }
}

export const getSentFaxes = (data) => {
  return async (dispatch) => {
    await axios.post(`${CONFIG.serverURL}/getsentfaxes`, { fromNumber: data }).then((res) => {
      dispatch({ type: CONSTS.GET_ALL_OUTBOX_FAXES, payload: res.data })
    })
  }
}

export const deleteSentFaxDetails = (sid, numbers) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/delsentfaxes`, { sid: sid })
      .then(async (res) => {
        await dispatch(getFaxesDetails())
        await dispatch(getSentFaxes(numbers))
      })
      .catch(async (error) => {
        await dispatch(getFaxesDetails())
        await dispatch(getSentFaxes(numbers))
      })
  }
}

export const deleteFaxDetails = (fax_id, folder) => {
  return (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const delete_fax = `${CONFIG.API_URL}/accounts/${account_id}/faxes/${folder}/${fax_id}`
    const delete_attachment = `${CONFIG.API_URL}/accounts/${account_id}/faxes/${folder}/${fax_id}/attachment`

    axios.all([axios.delete(delete_fax), axios.delete(delete_attachment)]).then(
      axios.spread(async (deleted_fax, deleted_attachment) => {
        dispatch(getFaxesDetails())
      }),
    )
  }
}
