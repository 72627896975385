import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ModalFooter, Spinner } from 'reactstrap'
import axios from 'axios'
import CONFIG from '../../../constants/config.json'
import './common-style.css'

async function getUsecaseByBrandId(brandId, usecaseId) {
  try {
    const response = await axios.get(`${CONFIG.serverURL}/campaign-registry/brand/${brandId}/usecase/${usecaseId}`)
    return Object.entries((response?.data ?? {})?.mnoMetadata ?? {}).map(([id, data]) => ({ id, ...data }))
  } catch (err) {
    return []
  }
}

const CarrierTermsPreviewSection = ({ onBack, onSubmit, brandId, usecaseId }) => {
  const [isLoading, setLoading] = useState(false)
  const [carriers, setCarriers] = useState([])
  const [selected, setSelected] = useState({})
  const isValid = useMemo(() => Object.values(selected).filter((el) => el).length > 0, [selected])

  useEffect(() => {
    if (brandId && usecaseId) {
      setLoading(true)
      getUsecaseByBrandId(brandId, usecaseId)
        .then(setCarriers)
        .then(() => setLoading(false))
    }
  }, [brandId, usecaseId])

  useEffect(() => {
    setSelected(
      carriers.reduce((prev, curr) => {
        prev[curr.id] = true
        return prev
      }, {}),
    )
  }, [carriers])

  const handleChange = useCallback((id) => {
    setSelected((prev) => ({ ...prev, [id]: prev[id] ? false : true }))
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit({
        mnoIds: Object.entries(selected)
          .filter(([_, value]) => value)
          .map(([key]) => key),
      })
    },
    [selected, onSubmit],
  )

  return (
    <form className="position-relative" style={{ minHeight: 500,backgroundColor:"white" ,borderRadius:"26px"}} onSubmit={handleSubmit} >
      {isLoading && (
        <div
          style={{ position: 'absolute', inset: 0, backdropFilter: 'blur(2px)', zIndex: 10 }}
          className="d-flex align-items-center justify-content-center"
        >
          {isLoading && <Spinner animation="grow" variant="primary" />}
        </div>
      )}
      <div style={{ marginTop: 20,marginLeft:"20px",paddingTop:"5px"}}>
        <h5>Carrier Terms Preview</h5>
        <p>The below list shows campaign qualification results and terms for each MNO</p>
      </div>

      <div className="table-inner">
        {carriers.map((el) => (
          <div className="row" key={el.id}>
            <div className="col-sm art-data">
              <input type="checkbox" checked={!!selected[el.id]} onChange={() => handleChange(el.id)} />
              <span className="mani-text-ui">{el.mno}</span>
            </div>
            <div className="col-sm">
              <ul className="ul-data-added">
                <li>
                  <p className="qt-top">Qualify</p>
                  <span className="qt-bottom">{el.qualify ? 'Yes' : 'No'}</span>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <ul className="ul-data-added">
                <li>
                  <p className="qt-top">MNO REVIEW</p>
                  <span className="qt-bottom">{el.mnoReview ? 'Yes' : 'No'}</span>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <ul className="ul-data-added">
                <li>
                  <p className="qt-top">{el?.brandTier ? 'BRAND TIER' : 'TPM SCOPE'}</p>
                  <span className="qt-bottom">{el?.brandTier || el.tpmScope || 'N/A'}</span>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <ul className="ul-data-added">
                <li>
                  <p className="qt-top">{el?.brandDailyCap ? 'BRAND DAILY CAP' : 'SMS TPM'}</p>
                  <span className="qt-bottom">{el.brandDailyCap || el?.tpm || 'N/A'}</span>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <ul className="ul-data-added">
                <li>
                  <p className="qt-top">MMS TPM</p>
                  <span className="qt-bottom">{el.mmsTpm ?? 'N/A'}</span>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <ul className="ul-data-added">
                <li>
                  <p className="qt-top">MESSAGE CLASS</p>
                  <span className="qt-bottom">{el.msgClass ?? 'N/A'}</span>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
      <ModalFooter style={{ gap: 8, display: 'flex' }}>
        <Button type="button" style={{ backgroundColor: 'green', color: 'white' }} onClick={onBack}>
          Back
        </Button>
        <Button type="submit" color="primary" disabled={!isValid}>
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

export default CarrierTermsPreviewSection
