import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/storage'
import firebaseConfigData from './constants/firebaseConfigData.json'


const firebaseConfig = {
  apiKey: firebaseConfigData.FIREBASE_API_KEY,
  authDomain: firebaseConfigData.FIREBASE_AUTH_DOMAIN,
  databaseURL: firebaseConfigData.FIREBASE_DB_URL,
  projectId: firebaseConfigData.FIREBASE_PROJECT_ID,
  storageBucket: firebaseConfigData.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: firebaseConfigData.FIREBASE_MESSAGING_SENDER_ID,
  appId: firebaseConfigData.FIREBASE_APP_ID,
  measurementId: firebaseConfigData.FIREBASE_MEASUREMENT_ID,
}
const app = firebase.initializeApp(firebaseConfig)
export const Storage = app.storage()

export const messaging = firebase.messaging()

const REACT_APP_VAPID_KEY = firebaseConfigData.FIREBASE_VAPID_KEY

export const getToken = async () => {
  try {
    const token = await messaging.getToken({
      vapidKey: REACT_APP_VAPID_KEY,
    })
    return token
  } catch (error) {
    console.log('temp',error)
    return null
  }
}

export const getFirebaseToken = async () => {
  try {
    const getTokenData = await messaging.getToken({
      vapidKey: REACT_APP_VAPID_KEY,
    })
    return getTokenData
  } catch (err) {
    console.log(err, 'somthing went wrongs')
  }
}
