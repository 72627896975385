import * as CONSTS from '../constants/const'

let defaultState = {
  redTailSyncState: false,
  redTailAuthToken: {},
  redTailContacts: [],
  redTailAddContact: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.RED_SYNC_STATE:
      return { ...state, redTailSyncState: action.payload }
    case CONSTS.REDTAIL_AUTH_TOKEN:
      return { ...state, redTailAuthToken: action.payload }
    case CONSTS.GET_ALL_REDTAIL_CONTACTS_LIST:
      return { ...state, redTailContacts: action.payload }
    case CONSTS.REDTAIL_ADD_CONTACTS:
      return { ...state, redTailAddContact: action.payload }
    default:
      return state
  }
}
