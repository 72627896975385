import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import './message.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { deleteContact } from '../../actions/message.action'

const ContactDelete = (props) => {
  const dispatch = useDispatch()
  const contactId = props.contact_id
  const deleteContactData = () => {
    let data = Array.isArray(contactId) ? contactId : [contactId]
    const distributions = props.distribution || []
    const contacts = props.contacts || []

    let output = []
    for (let id of data) {
      output.push(id)
      const contact = contacts.find((x) => x._id === id)
      if (contact) {
        Array.prototype.push.apply(
          output,
          distributions.filter((x) => x.phoneNumber === contact.phoneNumber).map((x) => x._id),
        )
      }
      const matchedDistribution = distributions.find((x) => x._id === id)
      if (matchedDistribution) {
        const similarItems = distributions.filter(
          (x) => x.phoneNumber === matchedDistribution.phoneNumber && matchedDistribution._id !== x._id,
        )
        Array.prototype.push.apply(
          output,
          similarItems.map((x) => x._id),
        )
      }
    }
    output = [...new Set(output)]
    dispatch(deleteContact(output))
    props.toggleDeleteDialog(!props.showDeleteDialog)
    props.toggleContactDialog(!props.showDeleteDialog)
  }

  const toggle = () => {
    props.toggleDeleteDialog(!props.showDeleteDialog)
  }

  return (
    <Fragment>
      <Modal
        isOpen={props.showDeleteDialog}
        toggle={toggle}
        className={`modalHeader del-contact-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader></ModalHeader>
        <ModalBody className="delete-body">
          <div className="delete-icon">
            <i className="fas fa-trash"></i>
          </div>
          <div>
            <h5>
              Are you sure you want to <br /> delete this contact?
            </h5>
          </div>
        </ModalBody>
        <ModalFooter className="delete-footer">
          <Button className="assign-footer-btn cancel-btn" onClick={toggle}>
            {'Cancel'}
          </Button>
          <Button color="primary" className="deleteContact" onClick={deleteContactData}>
            {'Delete'}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default ContactDelete
