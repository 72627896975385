import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { v4 } from 'uuid'

const CampaignSampleMessage = React.memo(({ onChange }) => {
  const [messages, setMessages] = useState([])

  const addNewMessage = useCallback(() => {
    setMessages((prev) => [
      ...prev,
      {
        content: '',
        id: v4(),
      },
    ])
  }, [])

  const handleContentChange = useCallback((e, index) => {
    e.persist()
    setMessages((prev) => {
      prev[index].content = e.target.value
      return [...prev]
    })
  }, [])

  useEffect(() => {
    onChange(messages)
  }, [messages, onChange])

  const handleMessageRemove = useCallback((id) => {
    setMessages((prev) => prev.filter((el) => el.id !== id))
  }, [])

  useEffect(() => {
    addNewMessage()
  }, [addNewMessage])

  return (
    <div>
      <div>
        <h5 className="msg-row-display">Sample messages</h5>
      </div>
      <div style={{ marginTop: '-20px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {messages.map((el, index) => (
            <div key={el.id} style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-20px' }}>
                <p style={{ marginLeft: '12px', marginTop: '0', marginBottom: '1rem' }}>Sample message {index + 1}</p>
                {index > 0 && (
                  <p
                    style={{ marginLeft: '12px', marginTop: '0', marginBottom: '1rem' }}
                    onClick={() => handleMessageRemove(el.id)}
                  >
                    <i style={{ color: '#3db16b' }} className="fa fa-trash" aria-hidden="true"></i>
                  </p>
                )}
              </div>
              <textarea
                style={{ width: '34em', height: '70%', margin: '10px', border: '1px solid #000', borderRadius: '10px' }}
                value={el.content}
                onChange={(e) => handleContentChange(e, index)}
              />
            </div>
          ))}
        </div>

        <div>
          <Button
            style={{ backgroundColor: '#3db16b', margin: '10px' }}
            onClick={addNewMessage}
            disabled={messages.length === 5}
          >
            + Add sample message
          </Button>
        </div>
      </div>
    </div>
  )
})

export default CampaignSampleMessage
