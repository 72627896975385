// @flow
import * as CONSTS from '../constants/const'

let defaultState = { allNumbers: [], allUsers: [], groupUsers: [], e911Errors: '' }

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_ADMINDID_NUMBERS:
      return { ...state, allNumbers: action.payload }
    case CONSTS.GET_ADMINDID_USERS:
      return { ...state, allUsers: action.payload }
    case CONSTS.GET_ADMINDID_GROUPUSERS:
      return { ...state, groupUsers: action.payload }
    case CONSTS.SAVE_E911_ERRORS:
      return { ...state, e911Errors: action.payload }
    default:
      return state
  }
}
