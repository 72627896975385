import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './campaignmanagement.css'
import { getCampaignList } from '../../actions/campaign.action'
import ReactPaginate from 'react-paginate'
import { useHistory, useLocation } from 'react-router'
import { useCallback } from 'react'
import moment from 'moment/moment'
import CreateCampaign from './CreateCampaign'
import { Button } from 'reactstrap'
import Axios from 'axios'
import CONFIG from '../../constants/config'
import { toast } from 'react-toastify'

const CampaignRegistryTable = (props) => {
  const { styleMode } = useSelector((state) => state.message.numbers)
  const [openCampaignModel, setOpenCampaignModel] = useState(false)
  const { campaignList } = useSelector((state) => state.campaignManagement)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setLoading] = useState(false)
  const params = new URLSearchParams(location.search)
  const page = params.get('page') || '1'
  const recordsPerPage = params.get('recordsPerPage') || '10'
  const [isDeleting, setDeleting] = useState({})

  const toggleCampaignModel = () => {
    setOpenCampaignModel(!openCampaignModel)
  }


  const handlePageChange = useCallback(
    ({ selected }) => {
      const updatedPage = String(selected + 1)
      if (updatedPage !== page) {
        history.push({
          pathname: history.location.pathname,
          search: `?page=${selected + 1}`,
        })
      }
    },
    [page, history],
  )

  const handleCampaignDeactivate = useCallback(async (campaignId) => {
    try {
      setDeleting(prev => ({ ...prev, [campaignId]: true }))
      await Axios.delete(`${CONFIG.serverURL}/campaign-registry/campaign/${campaignId}`);
      await dispatch(getCampaignList({ page, recordsPerPage }, () => { }))
    } catch (err) {
      toast.error("Something went wrong..")
    } finally {
      setDeleting(prev => ({ ...prev, [campaignId]: false }))
    }
  }, [page, recordsPerPage, dispatch])

  useEffect(() => {
    setLoading(true)
    dispatch(getCampaignList({ page, recordsPerPage }, () => setLoading(false)))
  }, [dispatch, page, recordsPerPage])

  return (
    <div className={styleMode ? styleMode : 'light'}>
      <div className="layout-user">
        <div className="content">
          <div className="campaign">
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="brand-inner report-table-content">
                    <div onClick={toggleCampaignModel}>
                      <CreateCampaign />
                    </div>
                    <table className="table table-striped mb-0 client-table mt-4">
                      <thead>
                        <tr>
                          <th>CAMPAIGN ID</th>
                          <th>BRAND ID</th>
                          <th>USE-CASE</th>
                          <th>REGISTERED ON</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaignList?.records?.length > 0 &&
                          campaignList?.records.map((campaing, index) => (
                            <tr key={index}>
                              <td>{campaing.campaignId}</td>
                              <td>{campaing.brandId}</td>
                              <td>{campaing.usecase}</td>

                              <td>{moment(campaing.billedDate).format('YYYY-MM-DD')}</td>

                              <td>{campaing.status === 'ACTIVE' ? (
                                <Button color="primary" disabled={isDeleting[campaing?.campaignId]} onClick={() => handleCampaignDeactivate(campaing.campaignId)}>
                                  DE-ACTIVATE
                                </Button>
                              ) : campaing.status}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {isLoading ? (
                      <p className="loading-text">Loading...</p>
                    ) : (
                      <div className="pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageChange}
                          pageCount={Math.floor(campaignList.totalRecords || 0) / Number(recordsPerPage)}
                          previousLabel="Previous"
                          initialPage={Number(page) - 1}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignRegistryTable
