import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Sidebar from '../Sidebar/Sidebar'
import { getCallForward } from '../../actions/message.action'
import Dialog from '../Sidebar/Dialog'
import './styles.css'
import {
  getFaxesDetails,
  sendFax,
  deleteFaxDetails,
  getSentFaxes,
  deleteSentFaxDetails,
} from '../../actions/faxes.action'
import telIcon from '../../asset/media/svg/telicon-2.2.0.svg'
import moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from 'axios'
import { AsYouType } from 'libphonenumber-js'

import CONFIG from '../../constants/config'
import Select from 'react-select'
import UserHeader from '../Header/UserHeader'

const Faxes = (props) => {
  const dispatch = useDispatch()

  const asYouType = new AsYouType('US')

  const { styleMode } = useSelector((state) => state.message.numbers)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const { inbox, outbox, faxboxNumbers } = useSelector((state) => state.faxes)
  const [active, setActive] = useState('inbox')
  const [sendNewFax, setSendNewFaxToogle] = useState(false)
  const uploadInput = useRef(null)
  const [sendFaxData, setSendFaxData] = useState({
    mediaUrl: '',
    fromNumber: '',
    toNumber: '',
  })

  const [details, setDetails] = useState([])
  const [faxNumbers, setFaxNumbers] = useState([])
  const [selectNumbersOptions, setSelectNumbersOptions] = useState([])

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const getDetails = (active) => {
    setActive(active)
    // dispatch(getFaxDetails(active))
  }

  const getDateTime = (timestamp) => {
    return moment.unix(timestamp - 62167219200).format('YYYY-MM-DD HH:mm:ss')
  }

  const convertDate = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  const imageUpload = (ev) => {
    ev.preventDefault()
    const data = new FormData()
    data.append('file', uploadInput.current.files[0])
    axios
      .post(`${CONFIG.serverURL}/faxfileupload`, data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => {
        setSendFaxData({
          ...sendFaxData,
          mediaUrl: `${CONFIG.serverURL}/faxes/${response.data.file}`,
        })
      })
  }

  const changeHandleFaxData = (e) => {
    if (e.target.id === 'toNumber') {
      setSendFaxData({
        ...sendFaxData,
        [e.target.id]: asYouType.input(e.target.value),
      })
    } else {
      setSendFaxData({
        ...sendFaxData,
        [e.target.id]: e.target.value,
      })
    }
  }

  const sendNewFaxModal = () => {
    setSendNewFaxToogle(!sendNewFax)
  }

  const saveFaxDetail = () => {
    asYouType.input(sendFaxData.toNumber)
    const getNumber = asYouType.getNumber().number

    const data = {
      mediaUrl: sendFaxData.mediaUrl,
      fromNumber: sendFaxData.fromNumber.value,
      toNumber: getNumber,
    }
    if (data.mediaUrl && data.fromNumber && data.toNumber) {
      dispatch(sendFax(data, faxNumbers))
      setSendNewFaxToogle(!sendNewFax)
      setSendFaxData({
        mediaUrl: '',
        fromNumber: '',
        toNumber: '',
      })
    }
  }

  const account_id = localStorage.getItem('account_id')
  const auth_token = localStorage.getItem('token')
  const user_id = localStorage.getItem('user_id')

  const deleteFax = (fax_id, folder) => {
    dispatch(deleteFaxDetails(fax_id, folder))
  }
  const deleteSentFax = (fax_sid) => {
    dispatch(deleteSentFaxDetails(fax_sid, faxNumbers))
  }

  const selectNumber = (value) => {
    setSendFaxData({
      ...sendFaxData,
      fromNumber: value,
    })
  }

  useEffect(() => {
    const numberArr = []
    const faxNumArr = []
    faxboxNumbers &&
      faxboxNumbers.forEach((faxnumber) => {
        if (faxnumber.owner_id === user_id || faxnumber.owner_id === account_id) {
          numberArr.push({ value: faxnumber.caller_id, label: faxnumber.name })
          faxNumArr.push(faxnumber.caller_id)
        }
      })
    setSelectNumbersOptions(numberArr)
    setFaxNumbers(faxNumArr)
    // eslint-disable-next-line
  }, [faxboxNumbers])

  useEffect(() => {
    dispatch(getFaxesDetails())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getSentFaxes(faxNumbers))
    // eslint-disable-next-line
  }, [faxNumbers])

  useEffect(() => {
    if (active === 'inbox') {
      setDetails(inbox)
    }
  }, [active, inbox])

  return (
    <div className={styleMode}>
      <ToastContainer autoClose={8000} />
      <div className="layout-user">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} />
          <div className="numbers">
            <div className="">
              <div className="faxbox-container">
                <div className="faxbox">
                  <div className={`${styleMode}-faxbox-wrapper faxbox-wrapper faxes-top-1`}>
                    <div className="pb-4">
                      <h2>{'Faxes'}</h2>
                    </div>
                    <div className="fax-mailbox">
                      <div className="cursor-pointer" onClick={() => getDetails('inbox')}>
                        <div className={`cursor-pointer ${active === 'inbox' ? 'newcount faxes-top' : 'totalcount'}`}>
                          {inbox.length}
                        </div>
                        <div className="num-title">Inbox</div>
                      </div>
                      <div className="cursor-pointer" onClick={() => getDetails('outbox')}>
                        <div className={`cursor-pointer ${active === 'outbox' ? 'newcount faxes-top' : 'totalcount'}`}>
                          {outbox.length}
                        </div>
                        <div className="num-title">Sent</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="numbers-btns mt-4">
              <Button color="primary" onClick={() => sendNewFaxModal()}>
                Send New
              </Button>
            </div>
            <div>
              <table className="table table-striped mb-0 client-table mt-4 fax-table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date Time</th>
                    <th>From</th>
                    <th>To</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {active === 'inbox' &&
                    details &&
                    details.length > 0 &&
                    details.map((fax, index) => (
                      <tr key={index}>
                        <td className="td-center">{active === 'inbox' && !fax.status ? 'Received' : fax.status}</td>
                        <td className="td-center">{getDateTime(fax.created)}</td>
                        <td className="td-center">{fax.from}</td>
                        <td className="td-center">{fax.to}</td>
                        <td className="td-center">
                          <div className="col-md-12">
                            <div className="mailchange">
                              {active !== 'incoming' && (
                                <Fragment>
                                  <svg
                                    className="delete gray-icon"
                                    onClick={() => {
                                      if (window.confirm('Are you sure you want to delete this fax?')) {
                                        deleteFax(fax.id, fax.folder)
                                      }
                                    }}
                                  >
                                    <use href={`${telIcon}#trash`} />
                                  </svg>
                                  <a
                                    href={`${CONFIG.API_URL}/accounts/${account_id}/faxes/${fax.folder}/${fax.id}/attachment?auth_token=${auth_token}`}
                                  >
                                    <svg className="gray-icon">
                                      <use href={`${telIcon}#download-cloud`} />
                                    </svg>
                                  </a>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {active === 'outbox' &&
                    outbox &&
                    outbox.length > 0 &&
                    outbox.map((fax, index) => (
                      <tr key={index}>
                        <td className="td-center">{fax.status}</td>
                        <td className="td-center">{convertDate(fax.dateCreated)}</td>
                        <td className="td-center">{fax.from}</td>
                        <td className="td-center">{fax.to}</td>
                        <td className="td-center">
                          <div className="col-md-12">
                            <div className="mailchange">
                              <Fragment>
                                <svg
                                  className="delete gray-icon"
                                  onClick={() => {
                                    if (window.confirm('Are you sure you want to delete this fax?')) {
                                      deleteSentFax(fax.sid)
                                    }
                                  }}
                                >
                                  <use href={`${telIcon}#trash`} />
                                </svg>
                                <a href={fax.mediaUrl}>
                                  <svg className="gray-icon">
                                    <use href={`${telIcon}#download-cloud`} />
                                  </svg>
                                </a>
                              </Fragment>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <Modal
            isOpen={sendNewFax}
            toggle={sendNewFaxModal}
            className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
          >
            <ModalHeader toggle={sendNewFaxModal}>
              <i className="fas fa-fax"></i> Send New Fax
            </ModalHeader>
            <ModalBody>
              <div className="contact">
                <label>Select File</label>
                <input
                  type="file"
                  className="form-control mb-3 display-file"
                  ref={uploadInput}
                  onChange={imageUpload}
                  accept="image/*|application/*"
                />

                <label>From Number</label>
                <div className="mb-3">
                  <Select
                    isClearable
                    id="fromNumber"
                    value={sendFaxData.fromNumber}
                    onChange={selectNumber}
                    options={selectNumbersOptions}
                    placeholder="From Number"
                    maxMenuHeight={200}
                  />
                </div>

                {/* <label>To Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  id="to_name"
                  placeholder="To Name"
                  value={sendFaxData.to_name}
                  onChange={changeHandleFaxData}
                /> */}

                <label>To Number</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="To Number"
                  value={sendFaxData.toNumber}
                  id="toNumber"
                  onChange={changeHandleFaxData}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={sendNewFaxModal}>
                Cancel
              </Button>

              <Button color="primary" onClick={saveFaxDetail}>
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Faxes
