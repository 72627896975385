import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from '../Sidebar/Sidebar'
import { getCallForward } from '../../actions/message.action'
import Dialog from '../Sidebar/Dialog'
import UserHeader from '../Header/UserHeader'
import './userdashboard.css'
import { Line, Pie } from 'react-chartjs-2'
import {
  getAllUsers,
  getCallHistory,
  getDevices,
  getNumbers,
  getAccountInfo,
  getMessageReport,
  getTopSenders,
} from '../../actions/dashboard.action'
import moment from 'moment'
import Config from '../../constants/config.json'
import { useHistory } from 'react-router'
import { getallvmboxes } from '../../actions/voicemails.action'
import Axios from 'axios'

const deviceMapping = {
  landline: 'Landline',
  softphone: 'Soft phone',
  sip_device: 'SIP phone',
  cellphone: 'Cell phone',
}

function fancyTimeFormat(duration) {
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60
  var ret = ''
  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }
  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

function getLineChart(data) {
  const groupByDate = (data || []).reduce((prev, curr) => {
    const key = moment(curr.datetime).format('DD/MM/YYYY')
    if (!prev[key]) {
      prev[key] = {
        date: moment(curr.datetime).toDate(),
        inbound: 0,
        outbound: 0,
        label: key,
      }
    }
    const isOutboundCall = !!curr?.authorizing_id?.length
    if (isOutboundCall) {
      prev[key].outbound += 1
    } else {
      prev[key].inbound += 1
    }

    return prev
  }, {})

  const formattedData = Object.values(groupByDate).reverse()
  const labels = formattedData.map((el) => el.label)
  const outbound = formattedData.map((el) => el.outbound)
  const inbound = formattedData.map((el) => el.inbound)

  return {
    labels,
    outbound,
    inbound,
  }
}

function getCallhistoryMetrics(data) {
  const output = {
    total: {
      count: 0,
      duration: 0,
    },
    inbound: {
      count: 0,
      duration: 0,
    },
    outbound: {
      count: 0,
      duration: 0,
    },
  }
  if (!data) {
    return output
  }
  data.forEach((el) => {
    const duration = el.billing_seconds || 0
    if (!el.authorizing_id?.length) {
      output.inbound.count += 1
      output.inbound.duration += duration
    } else {
      output.outbound.count += 1
      output.outbound.duration += duration
    }
    output.total.count += 1
    output.total.duration += duration
  })
  return output
}

function groupDevices(devices) {
  if (!devices) {
    return []
  }
  const output = {}
  devices.forEach((el) => {
    if (!output[el.device_type]) {
      output[el.device_type] = {
        count: 0,
        label: deviceMapping[el.device_type] || el.device_type,
      }
    }
    output[el.device_type].count += 1
  })
  return Object.values(output)
}

async function getUnreadMessage() {
  try {
    const account_id = localStorage.getItem('account_id')
    const owner_id = localStorage.getItem('user_id')
    const response = await Axios.post(`${Config.serverURL}/getUnreadCount`, { userId: owner_id, accountId: account_id })
    return response.data?.count || 0
  } catch (err) {
    return 0
  }
}

const UserDashboard = (props) => {
  const { styleMode } = useSelector((state) => state.message.numbers)
  const dispatch = useDispatch()
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  let history = useHistory()
  const { callHistory, devices: deviceResponse } = useSelector((state) => state.dashboard)
  const [unreadCount, setUnreadCount] = useState(0)
  const { voicemails } = useSelector((state) => state.voicemails)
  const callHistoryMetrics = useMemo(() => getCallhistoryMetrics(callHistory), [callHistory])
  const devices = useMemo(() => groupDevices(deviceResponse), [deviceResponse])

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }

  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const startDate = moment(moment().subtract(30, 'days')).unix()
  const endDate = moment().unix()

  useEffect(() => {
    getUnreadMessage().then(setUnreadCount)
  }, [])

  useEffect(() => {
    dispatch(getMessageReport())
    dispatch(getTopSenders())
    dispatch(getAllUsers())
    dispatch(getDevices())
    // dispatch(getActiveCallDetails())
    dispatch(getCallHistory(startDate, endDate))
    dispatch(getNumbers())
    dispatch(getAccountInfo())
    dispatch(getallvmboxes())
    // eslint-disable-next-line
  }, [])

  const dataArray = []
  for (let index = 0; index < 30; index++) {
    dataArray.push({
      label: moment(moment().subtract(index, 'days')).date(),
      value:
        callHistory &&
        callHistory.filter((call) => moment(call.datetime).date() === moment(moment().subtract(index, 'days')).date())
          .length,
    })
  }

  const lineChartData = useMemo(() => getLineChart(callHistory), [callHistory])
  const data = {
    labels: lineChartData.labels,
    datasets: [
      {
        label: 'Inbound',
        fill: false,
        lineTension: 0.1,
        borderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(255,99,132,1)',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: lineChartData.inbound,
      },
      {
        label: 'Outbound',
        fill: false,
        lineTension: 0.1,
        borderColor: 'rgb(99, 255, 132)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(99, 255, 132)',
        pointBackgroundColor: 'rgb(99, 255, 132))',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(99, 255, 132)',
        pointHoverBorderColor: 'rgb(99, 255, 132)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: lineChartData.outbound,
      },
    ],
  }

  const newVoiceMailCount = useMemo(() => voicemails?.[0]?.vmbox?.newcount || 0, [voicemails])
  const totalVoicemailCount = useMemo(() => voicemails?.[0]?.vmbox?.total || 0, [voicemails])

  const numbersData = useMemo(
    () => ({
      labels: ['New', 'Total'],
      datasets: [
        {
          data: [newVoiceMailCount, totalVoicemailCount],
          backgroundColor: ['#FF6384', '#63FF84'],
        },
      ],
    }),
    [totalVoicemailCount, newVoiceMailCount],
  )

  const handleSmsPage = () => {
    setTimeout(() => {
      history.push(`/home`)
    }, 1000)
  }

  const handleVoiceMailPage = () => {
      setTimeout(() => {
        history.push(`/voicemails`)
      }, 1000)
  }

  const getDevicesNameList = () => {
    return (
      <ul style={{ marginLeft: 10, fontSize: '20px' ,textAlign:"center"}}>
        {devices &&
          devices.length > 0 &&
          devices.map((dev) => {
            return (
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  paddingBlock: 4,
                  gap: 8,
                  textAlign:"center"
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    height: 30,
                    width: 30,
                    borderRadius: 20,
                    border: '1px solid gray',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin:"5px"
                  }}
                >
                  {dev.count}
                </span>
                <span style={{ display: 'inline-block' }}>{dev.label}</span>
              </li>
            )
          })}
      </ul>
    )
  }

  return (
    <div className={styleMode ? styleMode : 'light'}>
      <div className="layout-user">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="right-dash-middle">
            <div className="row">
              <div className="col-md-6">
                <div className="dashboard-box">
                  <div className="sms-sender">
                    <h3>Unread Text Messages</h3>
                  </div>
                  <div className="box-btm-cont">
                    <p onClick={handleSmsPage} className="Unread-message">
                      {unreadCount} Unread Text Messages
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="dashboard-box">
                  <div className="sms-sender">
                    <h3>Voice Mail</h3>
                  </div>
                  {newVoiceMailCount || totalVoicemailCount ? (
                    <div className="pie-data">
                      <div className="voice-mail-box" onClick={handleVoiceMailPage}>
                        <Pie
                          variant="subheading"
                          color="inherit"
                          className="p-8 mb-8"
                          data={numbersData}
                          width={200}
                          height={200}
                          options={{
                            maintainAspectRatio: false,
                          }}
                        />
                      </div>
                      <div className="voice-main">
                        {numbersData.datasets?.[0]?.data.map((el, index) => (
                          <div key={numbersData.labels[index]} className="voice-card">
                            <div
                              style={{
                                height: 20,
                                width: 20,
                                margin:3,
                                borderRadius: 10,
                                backgroundColor: numbersData.datasets[0].backgroundColor[index],
                              }}
                            ></div>
                            <div>
                              {el} {numbersData.labels[index]}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="no-voice-mail-div">
                      <p className="no-data-text">No data found</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="CallDevices-box">
                  <div className="sms-sender">
                    <h3 className='textcall'>Call Devices: {deviceResponse.length}</h3>
                  </div>
                  <div className="box-btm-cont">{getDevicesNameList()}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="PhoneCalls-box">
                  <div className="sms-sender">
                    <h3>Phone Calls Last 30 days: {callHistory.length}</h3>
                  </div>
                  <div>
                    <Line height={80} data={data} />
                  </div>
                  <div className="call-des">
                    <p>
                      {callHistoryMetrics.inbound.count} Inbound Calls -{' '}
                      {fancyTimeFormat(callHistoryMetrics.inbound.duration)} minutes
                    </p>
                    <p>
                      {callHistoryMetrics.outbound.count} Outbound Calls -{' '}
                      {fancyTimeFormat(callHistoryMetrics.outbound.duration)} minutes
                    </p>
                    <hr />
                    <p>
                      {callHistoryMetrics.total.count} Total Calls -{' '}
                      {fancyTimeFormat(callHistoryMetrics.total.duration)} minutes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDashboard
