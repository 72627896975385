import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCallForward } from '../../actions/message.action'
import UserHeader from '../Header/UserHeader'
import Dialog from '../Sidebar/Dialog'
import Sidebar from '../Sidebar/Sidebar'
import MultiStepProgressBar from './MultiStepProgressBar'
import './campaignmanagement.css'
import UsecaseSelectionSection from './campaign-create/usecase-selection-section'
import CarrierTermsPreviewSection from './campaign-create/carrier-terms-preview-section'
import CampaignCreateSection from './campaign-create/campaign-create-section'
import { toast } from 'react-toastify'
import axios from 'axios'
import CONFIG from '../../constants/config.json'
import { Spinner } from 'reactstrap'
import { useHistory } from 'react-router'

const fieldMapping = {
  brandId: 'Brand',
  usecase: 'Usecase',
  resellerId: 'Reseller',
  description: 'Description',
  embeddedLink: 'Embedded Link',
  embeddedPhone: 'Embedded Phone',
  termsAndConditions: 'Terms and condition',
  numberPool: 'Number pooling',
  ageGated: 'Age gated',
  directLending: 'Direct lending',
  subscriberOptin: 'Subscriber Opt In',
  subscriberOptout: 'Subscriber Opt out',
  subscriberHelp: 'Subscriber Help',
  sample1: 'Sample message 1',
  sample2: 'Sample message 2',
  sample3: 'Sample message 3',
  sample4: 'Sample message 4',
  sample5: 'Sample message 5',
  messageFlow: 'Message flow',
  helpMessage: 'Help message',
  mnoIds: 'MNO ids',
  optinKeywords: 'Opt in keywords',
  optoutKeywords: 'Opt out keywords',
  helpKeywords: 'Help keywords',
  optinMessage: 'Opt in message',
  optoutMessage: 'Opt out message',
}

const CreateCampaignPage = (props) => {
  const { styleMode } = useSelector((state) => state.message.numbers)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()

  const [state, setState] = useState({})

  const dispatch = useDispatch()
  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }

  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const handleUseCase = useCallback((form) => {
    setState((prev) => ({ ...prev, ...form }))
    setCurrentStep(2)
  }, [])

  const handlePreview = useCallback((form) => {
    setState((prev) => ({ ...prev, ...form }))
    setCurrentStep(3)
  }, [])

  const handleCampaignDetails = useCallback(
    async (form) => {
      setLoading(true)
      try {
        const data = { ...form, ...state }
        const payload = {
          brandId: data?.brand?.brandId ?? '',
          usecase: data?.usecase?.usecase ?? '',
          resellerId: data?.resellerId,
          description: data?.description,
          embeddedLink: data?.embeddedLink ?? false,
          embeddedPhone: data?.embeddedPhone ?? false,
          termsAndConditions: data?.termsAndConditions ?? false,
          numberPool: data?.numberPool ?? false,
          ageGated: data?.ageGated ?? false,
          directLending: data?.directLending ?? false,
          subscriberOptin: data?.subscriberOptin ?? false,
          subscriberOptout: data?.subscriberOptout ?? false,
          subscriberHelp: data?.subscriberHelp ?? false,
          sample1: data?.sample1 ?? '',
          sample2: data?.sample2 ?? '',
          sample3: data?.sample3 ?? '',
          sample4: data?.sample4 ?? '',
          sample5: data?.sample5 ?? '',
          messageFlow: data?.messageFlow ?? '',
          helpMessage: data?.helpMessage ?? '',
          mnoIds: data?.mnoIds ?? [],
          optinKeywords: data?.optinKeywords ?? '',
          optoutKeywords: data?.optoutKeywords ?? '',
          helpKeywords: data?.helpKeywords ?? '',
          optinMessage: data?.optinMessage ?? '',
          optoutMessage: data?.optoutMessage ?? '',
        }
        await axios.post(`${CONFIG.serverURL}/campaign-registry/builder`, payload)
        setLoading(false)
        history.push('/tabing')
      } catch (err) {
        const messages = err?.response?.data || []
        setLoading(false)
        if (messages?.length) {
          toast.error((fieldMapping[messages[0]?.field] || messages[0]?.field) + ' - ' + messages[0]?.description)
        }
      }
    },
    [state, history],
  )
  return (
    <div className={styleMode ? styleMode : 'light'}>
      <div className="layout-user">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="wrap-cam-card position-relative">
            {isLoading && (
              <div
                style={{ position: 'absolute', inset: 0, backdropFilter: 'blur(2px)', zIndex: 10 }}
                className="d-flex align-items-center justify-content-center flex-column"
              >
                <Spinner animation="grow" variant="primary" />
                <p>Creating campaign...</p>
              </div>
            )}
            <MultiStepProgressBar currentStep={currentStep} />
            {currentStep === 1 && <UsecaseSelectionSection onSubmit={handleUseCase} />}
            {currentStep === 2 && (
              <CarrierTermsPreviewSection
                onSubmit={handlePreview}
                onBack={() => setCurrentStep(1)}
                usecaseId={state?.usecase?.key}
                brandId={state?.brand?.brandId}
              />
            )}
            {currentStep === 3 && (
              <CampaignCreateSection
                onSubmit={handleCampaignDetails}
                onBack={() => setCurrentStep(2)}
                brand={state?.brand}
                usecase={state?.usecase}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCampaignPage
