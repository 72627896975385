import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Pie } from 'react-chartjs-2'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import Callforward from './Callforward'
import UserEdit from './UserEdit'
import { getAllUsers, getDevices } from '../../actions/dashboard.action'
import {
  getUserNumbers,
  getUsers,
  getUserCallForward,
  getUserDevices,
  getFollowDevices,
  resetPresenceStatus
} from '../../actions/management.action'
import { getCallForward } from '../../actions/message.action'
import UserHeader from '../Header/UserHeader'
import silhoutte from '../../asset/media/img/silhoutte.png'
import CONFIG from '../../constants/config.json'

import './styles.css'

const Management = (props) => {
  const dispatch = useDispatch()
  const { styleMode, isAdmin } = useSelector((state) => state.message.numbers)
  const { users, devices } = useSelector((state) => state.dashboard)
  const { userNumbers, userData } = useSelector((state) => state.management)
  const { loading } = useSelector((state) => state.message)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [managementModal, updateManagementModal] = useState(false)
  const [selectUser, setSelectUser] = useState({})
  const [editModal, updateEditModal] = useState(false)

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const changeManagementModal = () => {
    updateManagementModal(!managementModal)
  }

  const userCallforwarding = (user) => {
    changeManagementModal()
    setSelectUser(user)
    dispatch(getUserCallForward(user.id))
    dispatch(getUserDevices(user.id))
    dispatch(getFollowDevices(user.id))
  }

  const resetPresenceState = (user) => {
    dispatch(resetPresenceStatus(user.presence_id))
  }

  const checkDeviceState = (id) => {
    const deviceData = devices.filter((device) => device.owner_id === id)
    const registered = deviceData.filter((device) => device.registered === true)
    const unregistered = deviceData.filter((device) => device.registered === false)

    const stateData = {
      labels: ['Registered', 'Unregistered'],
      datasets: [
        {
          data: [registered.length, unregistered.length],
          backgroundColor: ['#63FF84', '#FF6384'],
        },
      ],
    }
    return stateData
  }
  const phoneNumFormat = (number) => {
    if (number && number.length > 4) {
      const phone_number = parsePhoneNumberFromString(number)
      const phoneNumber = phone_number.formatNational()
      return phoneNumber
    } else return 'Ext ' + number
  }
  const getNumbers = (id) => {
    let nums
    userNumbers.filter((number) => {
      if (number.owner_id && number.owner_id === id) {
        nums = number.numbers
      }
      return true
    })
    return nums
  }

  const getUserAvatar = (email) => {
    const userArr = userData.filter((user) => user.email === email)
    if (userArr.length > 0) {
      if (userArr[0].avatar) {
        return `${CONFIG.serverURL}/users/${userArr[0].avatar}`
      } else {
        return silhoutte
      }
    } else return silhoutte
  }
  const changeEditModal = () => {
    updateEditModal(!editModal)
  }
  const editUserData = (user) => {
    setSelectUser(user)
    changeEditModal()
  }

  useEffect(() => {
    if (!isAdmin) {
      props.history.push('/home')
    }
    //eslint-disable-next-line
  }, [isAdmin])

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(getDevices())
    dispatch(getUserNumbers())
    dispatch(getUsers())
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styleMode}>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="management">
            <div className="row">
              {users?.length > 0 &&
                users.map((user, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                    <div className="user-card">
                      <div className="header">
                        <img src={getUserAvatar(user.username)} alt="User" />
                        <div>
                          {user.first_name} {user.last_name}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="chart">
                          <Pie
                            variant="subheading"
                            color="inherit"
                            className="p-8 mb-8"
                            data={checkDeviceState(user.id)}
                            width={120}
                            height={120}
                            options={{
                              maintainAspectRatio: false,
                              responsive: false,
                              legend: {
                                display: false,
                              },
                            }}
                          />
                          <div className="user-numbers">
                            {getNumbers(user.id) &&
                              getNumbers(user.id).map((number, index) => (
                                <span key={index}>{phoneNumFormat(number)}</span>
                              ))}
                          </div>
                        </div>
                        <div className="footer">
                          <i className="fas fa-cog" onClick={() => userCallforwarding(user)} />
                          {/* <i className="fas fa-microphone" /> */}
                          <i className="fas fa-user-edit" onClick={() => editUserData(user)} />
                          <i className="fas fa-hand-sparkles" onClick={() => resetPresenceState(user)} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Callforward
            managementModal={managementModal}
            changeManagementModal={changeManagementModal}
            selectUser={selectUser}
          />
          <UserEdit editModal={editModal} changeEditModal={changeEditModal} selectUser={selectUser} />
        </div>
      </div>
    </div>
  )
}

export default Management
