import React, { useCallback, useState } from 'react'
import { Button, Input } from 'reactstrap'
import * as yup from 'yup'
import { useFormik } from 'formik'

const validationSchema = yup.object().shape({
  message: yup.string().required('Message is required').min(20, 'Minimum 20 characters is required'),
  keyword: yup.string().required('Keyword is required'),
})

const CampaignAttribute = React.memo(
  ({ label, type, canExpand = true, booleanField, form, onChange, keywordField, messageField, disabledNo }) => {
    const [view, setView] = useState('')
    const [isDetailsFilled, setDetailFilled] = useState(false)
    const { values, handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
      initialValues: {
        keyword: form[keywordField] ?? '',
        message: form[messageField] ?? '',
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit: (form) => {
        onChange(keywordField, form.keyword)
        onChange(messageField, form.message)
        setView('')
        setDetailFilled(true)
      },
    })

    const handleSelection = useCallback(
      (checked) => {
        onChange(booleanField, checked)
        if (!canExpand) {
          return
        }
        if (checked) {
          if (isDetailsFilled) {
            setView('VIEW')
          } else {
            setView('EDIT')
          }
        } else {
          setView('')
        }
      },
      [isDetailsFilled, canExpand, booleanField, onChange],
    )

    const handleEdit = useCallback(() => {
      setView('EDIT')
    }, [])

    const handleClose = useCallback(() => {
      setView('')
    }, [])

    return (
      <div className="text-data">
        <div className="text-box-detalis">
          {label}
          <input
            className="check-style"
            checked={form?.[booleanField] === true}
            onChange={() => handleSelection(true)}
            type="checkbox"
          />
          <label className="check-style">Yes</label>
          <input
            type="checkbox"
            checked={form?.[booleanField] === false}
            onChange={() => handleSelection(false)}
            className="check-style"
            disabled={disabledNo}
          />
          <label className="check-style">No</label>
          {view === 'EDIT' && (
            <form className="inner-form-detalis" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 sub-section">
                  <Input
                    type="text"
                    bsSize="lg"
                    placeholder={`Type ${type} Keywords here`}
                    name="keyword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.keyword}
                  />
                  {!!errors.keyword && !!touched.keyword && <span style={{ color: 'red' }}>{errors.keyword}</span>}
                </div>
                <div className="col-12 sub-section">
                  <Input
                    type="text"
                    bsSize="lg"
                    placeholder={`Type ${type} Message here`}
                    name="message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />
                  {!!errors.message && !!touched.message && <span style={{ color: 'red' }}>{errors.message}</span>}
                </div>
              </div>
              <Button className="done-text" type="submit">
                Done
              </Button>
            </form>
          )}
          {view === 'VIEW' && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', columnGap: 4 }}>
                <i onClick={handleEdit} className="fas fa-edit"></i>
                <i onClick={handleClose} className="fas fa-window-close"></i>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                <p>{type} Keywords:</p>
                <p>{values.keyword}</p>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                <p>{type} Message:</p>
                <p>{values.message}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  },
)

export default CampaignAttribute
