import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { setMemberNum, startQuickCall } from '../../actions/message.action'
import Select from 'react-select'
import './voicemails.css'

const QuickCall = (props) => {
  const { phoneNumber, fromNumber, toNumber, direction, contact } = props
  let phone_num = direction
    ? direction === 'inbound'
      ? fromNumber
      : toNumber
    : phoneNumber
      ? phoneNumber.split(' ').join('')
      : toNumber
  const [callToogle, updateCall] = useState(false)
  const [tooltipOpen2, setTooltipOpen2] = useState(false)
  const [selectNumbersOptions, setSelectNumbersOptions] = useState([])
  const [sendCallData, setSendCallData] = useState({
    device_id: '',
    to_number: contact === true ? toNumber : phone_num,
  })
  const [addCall, setAddCall] = useState(false)
  const [initiatingCall, setInitiatingCall] = useState(false)
  const toggleTooltip1 = () => setTooltipOpen2(!tooltipOpen2)

  const dispatch = useDispatch()
  const { devices } = useSelector((state) => state.message)
  const { styleMode } = useSelector((state) => state.message.numbers)

  const callModal = () => {
    if (!callToogle) {
      setSendCallData({ ...sendCallData, device_id: '', to_number: phone_num })
      dispatch(setMemberNum(''))
    }
    updateCall(!callToogle)
  }

  useEffect(() => {
    const deviceArr = []
    devices &&
      devices.forEach((device) => {
        deviceArr.push({ value: device.id, label: device.name })
      })

    setSelectNumbersOptions(deviceArr)
    // eslint-disable-next-line
  }, [devices])

  const changeHandleCallData = (e) => {
    setSendCallData({
      ...sendCallData,
      [e.target.id]: e.target.value,
    })
  }

  const selectNumber = (value) => {
    setSendCallData({
      ...sendCallData,
      device_id: value,
    })
  }

  const startCall = () => {
    setInitiatingCall(!initiatingCall)
    phone_num.replace(/[\s&\\#+()$~%.'":*?<>{}-]/g, '')
    const device_id = sendCallData.device_id && sendCallData.device_id.value
    dispatch(startQuickCall(device_id, phone_num))
    setSendCallData({
      device_id: '',
      to_number: contact ? toNumber : phone_num,
    })
  }

  const toggleInitiatingCall = () => {
    setInitiatingCall(!initiatingCall)
    setAddCall(!addCall)
  }

  return (
    <>
      {contact ? (
        <i id="newCall" onClick={callModal} className="fa fa-phone-alt contact-call"></i>
      ) : (
        <div className="btn btn-light mr-2" id="newCall" onClick={callModal}>
          <i className="fa fa-phone-alt"></i>
        </div>
      )}

      <Tooltip placement="top" isOpen={tooltipOpen2} autohide={false} target="newCall" toggle={toggleTooltip1}>
        Quick Call
      </Tooltip>

      <Modal
        isOpen={callToogle}
        toggle={callModal}
        className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ToastContainer autoClose={8000} />
        <ModalHeader toggle={callModal}>
          <i className="fa fa-phone-alt"></i> Call
        </ModalHeader>
        <ModalBody>
          <div className="contact">
            <span className="tab-title">To</span>
            <div className="input-group">
              <div className="col-md-12 quick-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="To:"
                  name="toPhoneNum"
                  id="to_number"
                  value={sendCallData.to_number}
                  onChange={changeHandleCallData}
                  pattern="[0-9, ]"
                />
              </div>
            </div>

            <span className="tab-title">From</span>
            <div className="input-group">
              <div className="col-md-12 quick-input">
                <Select
                  isClearable
                  id="device_id"
                  value={sendCallData.device_id}
                  onChange={selectNumber}
                  options={selectNumbersOptions}
                  placeholder="Select Device"
                  maxMenuHeight={200}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={startCall}>
            Start Call
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={initiatingCall}
        toggle={toggleInitiatingCall}
        className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={toggleInitiatingCall}>
          <i className="fa fa-phone-alt"></i> Call
        </ModalHeader>
        <ModalBody className="initiating-call">Initiating Call...</ModalBody>
      </Modal>
    </>
  )
}

export default QuickCall
