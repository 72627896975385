// @flow
import * as CONSTS from '../constants/const'

const defaultState = {
  accessToken : null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GOOGLE_OAUTH2: {
      return action.googleResponse;
    }
    default:
      return state;
  }
};