import axios from 'axios'
import CONFIG from '../constants/config'
import { getAllUsers } from './dashboard.action'
import * as CONSTS from '../constants/const'
import { toast } from 'react-toastify'

axios.defaults.headers.get['Content-Type'] = 'application/json'

export const getUserNumbers = () => {
  return (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    const URI = `${CONFIG.API_URL}/accounts/${account_id}/callflows?paginate=false`
    axios.get(URI).then((res) => {
      dispatch({ type: CONSTS.USER_NUMBERS, payload: res.data.data })
    })
  }
}
export const getUsers = () => {
  return (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    axios
      .post(`${CONFIG.serverURL}/getusers`, {
        accountId: account_id,
      })
      .then((res) => {
        dispatch({ type: CONSTS.GET_USERS, payload: res.data })
      })
  }
}

export const resetPresenceStatus = (presenceId) => {
  return async (dispatch) => {
    if (presenceId) {
      const account_id = localStorage.getItem('account_id')
      const auth_token = localStorage.getItem('token')
      axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
      const res = await axios.post(`${CONFIG.PRESENCE_URL}/accounts/${account_id}/presence/${presenceId}`)
      if (res.status === 200) {
        dispatch({
          type: CONSTS.RESET_PRESENCE_STATUS,
          payload: res.data,
        })
        toast.success(`Reset command sent for extension ${presenceId}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        dispatch({
          type: CONSTS.RESET_PRESENCE_STATUS,
          payload: {},
        })
        toast.warn(res.data.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }
}

export const getUserCallForward = (ownerId) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const getCall = `${CONFIG.API_URL}/accounts/${account_id}/users/${ownerId}`
    const result = await axios.get(getCall)
    if (result.status === 200) {
      dispatch({
        type: CONSTS.USER_CALL_FORWARD,
        payload: result.data.data,
      })
    } else {
      dispatch({
        type: CONSTS.USER_CALL_FORWARD,
        payload: {},
      })
    }
  }
}
export const saveUserCallForward = (updateData, ownerId) => {
  return async (dispatch, getState) => {
    const { userCallforward } = getState().management
    userCallforward['call_forward'] = updateData
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const getCall = `${CONFIG.API_URL}/accounts/${account_id}/users/${ownerId}`
    await axios.post(getCall, { data: userCallforward })
  }
}

export const getFollowDevices = (userId) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const URI = `${CONFIG.API_URL}/accounts/${account_id}/callflows?filter_type=mainUserCallflow&filter_owner_id=${userId}&paginate=false`
    try {
      const device_num = await axios.get(URI)
      const id = device_num.data ? device_num.data.data[0].id : ''
      dispatch({
        type: CONSTS.USER_FOLLOW_ID,
        payload: id,
      })

      const deviceURL = `${CONFIG.API_URL}/accounts/${account_id}/callflows/${id}`
      await axios.get(deviceURL).then((res) => {
        if (res.data.data && res.data.data.flow) {
          dispatch({
            type: CONSTS.USER_FOLLOW_DEVICES,
            payload: res.data.data.flow.data.endpoints,
          })
          dispatch({
            type: CONSTS.USER_FOLLOW_DATA,
            payload: res.data.data.flow.data,
          })
        }
      })
    } catch {
      console.log('error')
    }
  }
}

export const saveFollowMe = (endPoints, userId) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const { userCallFlowID } = getState().management
    const { userCallforward } = getState().management

    const deviceURL = `${CONFIG.API_URL}/accounts/${account_id}/callflows/${userCallFlowID}`
    const getCall = `${CONFIG.API_URL}/accounts/${account_id}/users/${userId}`

    let data = {}
    if (endPoints.length > 0) {
      userCallforward.smartpbx.find_me_follow_me = { enabled: true }
      await axios.post(getCall, { data: userCallforward })
      await axios.get(deviceURL).then((res) => {
        if (res.data.data && res.data.data.flow) {
          data = res.data.data
          data.flow.data.endpoints = endPoints
        }
      })
      await axios.post(deviceURL, { data: data })
    } else {
      if (userCallforward.smartpbx) {
        userCallforward.smartpbx.find_me_follow_me = { enabled: false }
        await axios.post(getCall, { data: userCallforward })
      }
    }
  }
}
export const getCellPhone = (id) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const deviceURL = `${CONFIG.API_URL}/accounts/${account_id}/devices/${id}`
    await axios.get(deviceURL).then((res) => {
      if (res.data) {
        const deviceNumber = res.data.data.call_forward.number
        const deviceName = res.data.data.name
        const cellPhoneVoicemail = res.data.data.call_forward.require_keypress
        const keepCallerID = res.data.data.call_forward.keep_caller_id
        const contactList = res.data.data.contact_list.exclude

        dispatch({
          type: CONSTS.USER_DEVICES_NAME,
          payload: {
            deviceName: deviceName,
            deviceNumber: deviceNumber,
            cellPhoneVoicemail: cellPhoneVoicemail,
            keepCallerID: keepCallerID,
            contactList: contactList,
          },
        })
      }
    })
  }
}
export const getUserDevices = (userId) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const deviceURL = `${CONFIG.API_URL}/accounts/${account_id}/devices?filter_owner_id=${userId}&paginate=false`
    await axios.get(deviceURL).then((res) => {
      dispatch({
        type: CONSTS.USER_DEVICES,
        payload: res.data.data,
      })
    })
  }
}

export const deleteCellPhoneData = (id, userId) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const deviceURL = `${CONFIG.API_URL}/accounts/${account_id}/devices/${id}`
    axios.delete(deviceURL).then((res) => {
      if (res.status === 200) {
        dispatch(getUserDevices(userId))
      }
    })
  }
}
export const editCellPhoneData = (data, id, userId) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const adddeviceURL = `${CONFIG.API_URL}/accounts/${account_id}/devices/${id}`
    axios.post(adddeviceURL, { data: data }).then((res) => {
      if (res.status === 200) {
        dispatch(getUserDevices(userId))
      }
    })
  }
}
export const saveNewCellPhone = (data, userId) => {
  return async (dispatch) => {
    let newDeviceID = ''
    let resultData = {}
    const account_id = localStorage.getItem('account_id')
    const deviceURL = `${CONFIG.API_URL}/accounts/${account_id}/devices`
    await axios.put(deviceURL, { data: data }).then((res) => {
      if (res.data) {
        newDeviceID = res.data.data.id
        resultData = res.data.data
        resultData.owner_id = userId
        const adddeviceURL = `${CONFIG.API_URL}/accounts/${account_id}/devices/${newDeviceID}`
        axios.post(adddeviceURL, { data: resultData }).then((res) => {
          if (res.status === 200) {
            dispatch(getUserDevices(userId))
          }
        })
      }
    })
  }
}
export const getUserData = (user) => {
  console.log("this user done");
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    if (user.username) {
      await axios
        .post(`${CONFIG.serverURL}/userchk`, {
          email: user.username,
          account_id: account_id,
          userName: user.first_name + ' ' + user.last_name,
          fcmToken: '',
        })
        .then((res) => {
          if (res.status === 200) dispatch(getUsers())
        })
    }
  }
}

export const saveEmailAlert = (state, userEmail) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/savemailalert`, {
        emailAlert: state,
        email: userEmail,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getUsers())
      })
  }
}

export const saveVoicemailAlert = (data, userId) => {
  return async (dispatch, getState) => {
    const { userCallforward } = getState().management
    userCallforward.vm_to_email_enabled = data
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const getCall = `${CONFIG.API_URL}/accounts/${account_id}/users/${userId}`
    await axios.post(getCall, { data: userCallforward })
  }
}

export const saveSoundAlert = (state, userEmail) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/savesoundalert`, {
        soundAlert: state,
        email: userEmail,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getUsers())
      })
  }
}

export const getUserDirectory = () => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const URI = `${CONFIG.API_URL}/accounts/${account_id}/directories?paginate=false`
    try {
      const result = await axios.get(URI)
      dispatch({
        type: CONSTS.USER_DIRECTORIES,
        payload: result.data.data,
      })
    } catch {
      console.log('error')
    }
  }
}

export const saveUserEditData = (data, userId) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const { firstName, lastName, directory, privLevel, timezone, language, mainExtNum, privacy, notificationEmail } =
      data
    const { userCallforward, userCallFlowID, userDirectories } = getState().management
    const userData = userCallforward
    userData.first_name = firstName
    userData.last_name = lastName
    userData.email = notificationEmail
    userData.priv_level = privLevel
    userData.presence_id = mainExtNum
    if (privacy !== '') {
      userData.caller_id_options = { outbound_privacy: privacy }
    } else if (userData.caller_id_options) {
      delete userData.caller_id_options
    }
    if (timezone !== '') {
      userData.timezone = timezone
    } else if (userData.timezone) {
      delete userData.timezone
    }
    if (language !== '') {
      userData.language = language
    } else if (userData.language) {
      delete userData.language
    }
    if (directory) {
      userData.directories = { [userDirectories[0].id]: userCallFlowID }
    } else if (userData.directories) {
      delete userData.directories[userDirectories[0].id]
    }
    const getCall = `${CONFIG.API_URL}/accounts/${account_id}/users/${userId}`
    await axios.post(getCall, { data: userData }).then((res) => {
      if (res.status === 200) dispatch(getAllUsers())
    })
  }
}

export const changeUserCredential = (email, password, userId) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const { userCallforward } = getState().management
    const userData = userCallforward
    userData.username = email
    userData.password = password
    const getCall = `${CONFIG.API_URL}/accounts/${account_id}/users/${userId}`
    await axios.post(getCall, { data: userData }).then((res) => {
      if (res.status === 200) dispatch(getAllUsers())
    })
  }
}
