import * as CONSTS from '../constants/const'

let defaultState = {
  callreports: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_CALL_REPORTS:
      return { ...state, callreports: action.payload }
    default:
      return state
  }
}
