import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import InputRange from 'react-input-range'

import {
  saveUserCallForward,
  saveFollowMe,
  deleteCellPhoneData,
  editCellPhoneData,
  saveNewCellPhone,
  getCellPhone,
  getUserData,
  saveVoicemailAlert,
  saveEmailAlert,
  saveSoundAlert,
} from '../../actions/management.action'
import classnames from 'classnames'
import telIcon from '../../asset/media/svg/telicon-2.2.0.svg'

const Callforward = ({ managementModal, changeManagementModal, selectUser }) => {
  const dispatch = useDispatch()

  const { styleMode } = useSelector((state) => state.message.numbers)
  const { userCallforward, userFollowDevices, userDevices, userDevice } = useSelector((state) => state.management)
  const { userData } = useSelector((state) => state.management)

  const [settingTab, setSettingTab] = useState('settingTab1')
  const [callMode, updateCallMode] = useState(false)
  const [failoverMode, updateFailoverMode] = useState(false)
  const [thisNumber, updateThisNumber] = useState('')
  const [leave, updateLeave] = useState(false)
  const [forward, updateForward] = useState(false)
  const [keep, updateKeep] = useState(false)
  const [followMe, setFollowMe] = useState(false)
  const [addDevice, setAddDevice] = useState({
    deviceName: '',
    deviceNumber: '',
  })
  const [cellPhoneModalState, setCellPhoneModalState] = useState(false)
  const [addDeviceToggle, setAddDeviceToggle] = useState(false)
  const [flowDevices, setFlowDevices] = useState([])
  const [deviceId, setDeviceId] = useState('')
  const [deviceBasic, setDeviceBasic] = useState(true)
  const [deviceAdvanced, setDeviceAdvanced] = useState(false)
  const [cellPhoneVoicemail, setCellPhoneVoicemail] = useState(true)
  const [keepCallerID, setKeepCallerID] = useState(true)
  const [contactList, setContactList] = useState(true)
  const [mailAlert, setMailAlert] = useState(false)
  const [voicemailAlert, setVoicemailAlert] = useState(false)
  const [soundNotification, setSoundNotification] = useState(true)

  const changeForwardMode = (mode) => {
    if (mode === 'off') {
      updateCallMode(false)
    } else if (mode === 'failover') {
      updateCallMode(true)
      updateFailoverMode(true)
    } else if (mode === 'on') {
      updateFailoverMode(false)
      updateCallMode(true)
    }
  }

  const settingTabToggole = (tab) => {
    if (settingTab !== tab) setSettingTab(tab)
    if (settingTab === 'settingTab3') {
      getUserSettingData()
    }
  }

  const changeCallForward = () => {
    const forwardData = {
      number: thisNumber,
      require_keypress: leave,
      direct_calls_only: forward,
      keep_caller_id: keep,
      enabled: callMode,
      failover: failoverMode,
      ignore_early_media: true,
      substitute: true,
    }
    dispatch(saveUserCallForward(forwardData, selectUser.id))
    changeManagementModal()
  }
  const changeThisNumber = (e) => {
    updateThisNumber(e.target.value)
  }
  const changeLeave = () => {
    updateLeave(!leave)
  }
  const changeForward = () => {
    updateForward(!forward)
  }
  const changeKeep = () => {
    updateKeep(!keep)
  }
  const addCellPhoneModal = () => {
    setAddDevice({ deviceName: '', deviceNumber: '' })
    setCellPhoneModalState(false)
    setAddDeviceToggle(true)
  }
  const editCellPhoneModal = (id) => {
    setDeviceId(id)
    setCellPhoneModalState(true)
    dispatch(getCellPhone(id))
    setAddDeviceToggle(true)
  }
  const changeTimeLine = (value, id) => {
    const newData = [...flowDevices]
    const index = newData.findIndex((device) => device.id === id)
    newData[index].min = value.min
    newData[index].max = value.max
    setFlowDevices(newData)
  }
  const changeTimeLineState = (id, state) => {
    const newData = [...flowDevices]
    const index = newData.findIndex((device) => device.id === id)
    newData[index].ringState = !state
    setFlowDevices(newData)
  }
  const saveFollowState = () => {
    const array = []
    if (followMe === true) {
      flowDevices.forEach((device) => {
        if (device.ringState === true)
          array.push({
            id: device.id,
            endpoint_type: 'device',
            delay: device.min,
            timeout: Number(device.max - device.min),
          })
      })
    }
    dispatch(saveFollowMe(array, selectUser.id))
    changeManagementModal()
  }
  const toggleAddDevice = () => {
    setAddDeviceToggle(!addDeviceToggle)
  }
  const changeDeviceSetting = (mode) => {
    if (mode === 'basic') {
      setDeviceBasic(true)
      setDeviceAdvanced(false)
    } else if (mode === 'advanced') {
      setDeviceBasic(false)
      setDeviceAdvanced(true)
    }
  }
  const changeNewDevice = (e) => {
    setAddDevice({ ...addDevice, [e.target.id]: e.target.value })
  }
  const deleteCellPhone = () => {
    dispatch(deleteCellPhoneData(deviceId, selectUser.id))
    toggleAddDevice()
  }
  const editCellPhone = () => {
    const cellPhoneData = {
      call_forward: {
        enabled: true,
        keep_caller_id: keepCallerID,
        number: addDevice.deviceNumber,
        require_keypress: cellPhoneVoicemail,
      },
      contact_list: {
        exclude: contactList,
      },
      device_type: 'cellphone',
      enabled: true,
      name: addDevice.deviceName,
      media: {
        encryption: {
          enforce_security: false,
        },
        audio: {
          codecs: ['PCMU', 'PCMA'],
        },
        video: {
          codecs: [],
        },
      },
      id: deviceId,
      owner_id: selectUser.id,
      suppress_unregister_notifications: true,
    }
    dispatch(editCellPhoneData(cellPhoneData, deviceId, selectUser.id))
    toggleAddDevice(false)
  }
  const addCellPhone = () => {
    const newCellPhoneData = {
      call_forward: {
        enabled: true,
        keep_caller_id: keepCallerID,
        number: addDevice.deviceNumber,
        require_keypress: cellPhoneVoicemail,
      },
      contact_list: {
        exclude: contactList,
      },
      device_type: 'cellphone',
      enabled: true,
      name: addDevice.deviceName,
      media: {
        encryption: {
          enforce_security: false,
        },
        audio: {
          codecs: ['PCMU', 'PCMA'],
        },
        video: {
          codecs: [],
        },
      },
      suppress_unregister_notifications: true,
    }
    dispatch(saveNewCellPhone(newCellPhoneData, selectUser.id))
    toggleAddDevice(false)
  }
  const getFlowDevices = () => {
    const array = []
    let i = 0
    if (userFollowDevices && userFollowDevices.length > 0) {
      userDevices.forEach((device) => {
        i++
        const index = userFollowDevices.findIndex((followDevice) => device.id === followDevice.id)
        if (index === -1) {
          array.push({
            id: device.id,
            name: device.name,
            deviceType: device.device_type,
            min: 0,
            max: 0,
            ringState: false,
          })
        } else {
          array.push({
            id: device.id,
            name: device.name,
            deviceType: device.device_type,
            min: userFollowDevices[index].delay,
            max: userFollowDevices[index].delay + userFollowDevices[index].timeout,
            ringState: true,
          })
        }
        if (i === userDevices.length) {
          setFlowDevices(array)
          return
        }
      })
    } else {
      userDevices.forEach((device) => {
        i++
        array.push({
          id: device.id,
          name: device.name,
          deviceType: device.device_type,
          min: 0,
          max: 0,
          ringState: false,
        })
        if (i === userDevices.length) {
          setFlowDevices(array)
          return
        }
      })
    }
  }
  const saveEmailAlertState = () => {
    dispatch(saveEmailAlert(mailAlert, selectUser.email))
    dispatch(saveVoicemailAlert(voicemailAlert, selectUser.id))
    dispatch(saveSoundAlert(soundNotification, selectUser.email))
    changeManagementModal()
  }

  const getUserSettingData = () => {
    const userArr = userData.filter((user) => user.email === selectUser.email)
    if (userArr.length > 0) {
      setMailAlert(userArr[0].emailAlert)
      setSoundNotification(userArr[0].soundAlert)
    }
  }
  useEffect(() => {
    if (userCallforward && userCallforward.call_forward) {
      const { call_forward } = userCallforward
      updateCallMode(call_forward.enabled)
      updateFailoverMode(call_forward.failover)
      updateThisNumber(call_forward.number)
      updateLeave(call_forward.require_keypress)
      updateForward(call_forward.direct_calls_only)
      updateKeep(call_forward.keep_caller_id)
    } else {
      updateThisNumber('')
      updateCallMode(false)
      updateFailoverMode(false)
      updateLeave(false)
      updateForward(false)
      updateKeep(false)
    }
    if (userCallforward) {
      setVoicemailAlert(userCallforward.vm_to_email_enabled)
      if (userCallforward.smartpbx && userCallforward.smartpbx.find_me_follow_me)
        setFollowMe(userCallforward.smartpbx.find_me_follow_me.enabled)
      else {
        setFollowMe(false)
      }
    }
    // eslint-disable-next-line
  }, [userCallforward])
  useEffect(() => {
    if (Object.keys(userDevice).length !== 0) {
      setAddDevice({ ...addDevice, deviceName: userDevice.deviceName, deviceNumber: userDevice.deviceNumber })
      setCellPhoneVoicemail(userDevice.cellPhoneVoicemail)
      setKeepCallerID(userDevice.keepCallerID)
      setContactList(userDevice.contactList)
    }
    // eslint-disable-next-line
  }, [userDevice])
  useEffect(() => {
    getFlowDevices()
    // eslint-disable-next-line
  }, [userDevices, userFollowDevices])
  useEffect(() => {
    dispatch(getUserData(selectUser))
    // eslint-disable-next-line
  }, [selectUser])
  return (
    <>
      <Modal
        size="lg"
        isOpen={managementModal}
        toggle={changeManagementModal}
        className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={changeManagementModal}>
          <i className="fas fa-cog" /> Setting
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: settingTab === 'settingTab1',
                })}
                onClick={() => {
                  settingTabToggole('settingTab1')
                }}
              >
                Call Forwarding
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: settingTab === 'settingTab2',
                })}
                onClick={() => {
                  settingTabToggole('settingTab2')
                }}
              >
                Follow me / Find me
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: settingTab === 'settingTab3',
                })}
                onClick={() => {
                  settingTabToggole('settingTab3')
                }}
              >
                Notifications
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={settingTab}>
            <TabPane tabId="settingTab1">
              <span className="tab-title">User Call Forwarding Settings</span>
              <div className="tab-content">
                <div className="tab-pane show active" role="tabpanel">
                  <div className="call-forward-btns">
                    <div
                      className={`btn call-forward-${styleMode} ${!callMode ? 'btn-primary' : ''}`}
                      onClick={() => changeForwardMode('off')}
                    >
                      Off
                    </div>
                    <div
                      className={`btn call-forward-${styleMode} ${callMode && failoverMode ? 'btn-primary' : ''}`}
                      onClick={() => changeForwardMode('failover')}
                    >
                      Failover Mode
                    </div>
                    <div
                      className={`btn call-forward-${styleMode} ${callMode && !failoverMode ? 'btn-primary' : ''}`}
                      onClick={() => changeForwardMode('on')}
                    >
                      On
                    </div>
                  </div>
                  {callMode && (
                    <div className="mt-3">
                      {failoverMode && (
                        <span>
                          In "Failover Mode", the call-forward settings will only apply when none of this user's devices
                          are registered.
                        </span>
                      )}
                      <div>
                        <label htmlFor="allcalls" className="col-form-label">
                          Forward all calls to
                        </label>
                        <select className="form-control" id="allcalls">
                          <option value="1">A Mobile Phone</option>
                          <option value="2">A Desk Phone</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="thisnumber" className="col-form-label">
                          This Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="thisnumber"
                          placeholder="+120812345678"
                          value={thisNumber}
                          onChange={changeThisNumber}
                        />
                      </div>
                      <div className="mt-3">
                        <div className="form-item custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="leave"
                            checked={leave === false}
                            onChange={changeLeave}
                          />
                          <label className="custom-control-label" htmlFor="leave">
                            Leave voicemails on forwarded numbers
                          </label>
                        </div>
                        <div className="form-item custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="forward"
                            checked={forward === true}
                            onChange={changeForward}
                          />
                          <label className="custom-control-label" htmlFor="forward">
                            Forward direct calls only
                          </label>
                        </div>
                        <div className="form-item custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="keep"
                            checked={keep === true}
                            onChange={changeKeep}
                          />
                          <label className="custom-control-label" htmlFor="keep">
                            Keep Original Caller-ID
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-right">
                  <Button color="primary" onClick={changeCallForward}>
                    Save
                  </Button>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="settingTab2">
              <div className="following-modal-header">
                <div className="form-item custom-control custom-switch mt-2 ">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={followMe === true}
                    id="followmeCheck"
                    onChange={() => setFollowMe(!followMe)}
                  />
                  <label className="custom-control-label" htmlFor="followmeCheck">
                    Find me, Follow me Settings
                  </label>
                </div>
                <Button color="primary" className="add-new-phone" onClick={addCellPhoneModal}>
                  + Add a Cell Phone
                </Button>
              </div>

              {followMe && (
                <div>
                  <div className="row mb-3 mt-5">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-7">
                      <div className="scale-container">
                        <div className="scale-element">
                          <span>20 Sec</span>
                        </div>
                        <div className="scale-element">
                          <span>40 Sec</span>
                        </div>
                        <div className="scale-element">
                          <span>60 Sec</span>
                        </div>
                        <div className="scale-element">
                          <span>80 Sec</span>
                        </div>
                        <div className="scale-element">
                          <span>100 Sec</span>
                        </div>
                        <div className="scale-element">
                          <span>120 Sec</span>
                        </div>
                        <span>0 Sec</span>
                      </div>
                    </div>
                    <div className="col-sm-2 check-ring-state">Enable</div>
                  </div>
                  {flowDevices &&
                    flowDevices.length > 0 &&
                    flowDevices.map((device, index) => (
                      <div className="row mb-5" key={index}>
                        <div className="col-sm-3 check-ring-name">
                          {device.deviceType === 'cellphone' && (
                            <div onClick={() => editCellPhoneModal(device.id)}>
                              <svg className="device-icon cell-phone">
                                <use href={`${telIcon}#phone`} />
                              </svg>
                              <span className="cell-phone-text">{device.name}</span>
                            </div>
                          )}
                          {device.deviceType === 'sip_device' && (
                            <div>
                              <svg className="device-icon">
                                <use href={`${telIcon}#device-voip-phone`} />
                              </svg>
                              <span>{device.name}</span>
                            </div>
                          )}
                          {device.deviceType === 'softphone' && (
                            <div>
                              <svg className="device-icon">
                                <use href={`${telIcon}#device-soft-phone`} />
                              </svg>
                              <span>{device.name}</span>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-7 check-ring-state">
                          <InputRange
                            maxValue={120}
                            minValue={0}
                            value={{ min: device.min, max: device.max }}
                            disabled={device.ringState === false}
                            onChange={(v) => changeTimeLine(v, device.id)}
                          />
                        </div>
                        <div className="col-sm-2 check-ring-state">
                          <div className="form-item custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={device.ringState === true}
                              id={`ringChk${index}`}
                              onChange={() => changeTimeLineState(device.id, device.ringState)}
                            />
                            <label className="custom-control-label" htmlFor={`ringChk${index}`}></label>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className="text-right">
                <Button color="primary mt-3" onClick={saveFollowState}>
                  Save
                </Button>
              </div>
            </TabPane>

            <TabPane tabId="settingTab3">
              <div className="form-item custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={mailAlert === true}
                  id="emailalert"
                  onChange={() => setMailAlert(!mailAlert)}
                />
                <label className="custom-control-label" htmlFor="emailalert">
                  SMS Email Alerts
                </label>
              </div>
              <div className="form-item custom-control custom-switch mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={voicemailAlert === true}
                  id="voicealert"
                  onChange={() => setVoicemailAlert(!voicemailAlert)}
                />
                <label className="custom-control-label" htmlFor="voicealert">
                  Voicemails to Email Alerts
                </label>
              </div>
              <div className="form-item custom-control custom-switch mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={soundNotification === true}
                  id="soundalert"
                  onChange={() => setSoundNotification(!soundNotification)}
                />
                <label className="custom-control-label" htmlFor="soundalert">
                  Sound Alerts
                </label>
              </div>
              <div className="text-right">
                <Button color="primary" onClick={saveEmailAlertState}>
                  Save
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={addDeviceToggle}
        toggle={toggleAddDevice}
        className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={toggleAddDevice}>
          <svg className="device-icon">
            <use href={`${telIcon}#phone`} />
          </svg>
          {cellPhoneModalState ? 'Edit a Cell Phone' : 'Add a Cell Phone'}
        </ModalHeader>
        <ModalBody>
          <div className="call-forward-btns">
            <div
              className={`btn call-forward-${styleMode} ${deviceBasic ? 'btn-primary' : ''}`}
              onClick={() => changeDeviceSetting('basic')}
            >
              Basic setting
            </div>
            <div
              className={`btn call-forward-${styleMode} ${deviceAdvanced ? 'btn-primary' : ''}`}
              onClick={() => changeDeviceSetting('advanced')}
            >
              Advanced
            </div>
          </div>
          {deviceBasic && (
            <div>
              <label htmlFor="deviceName" className="col-form-label">
                Device Name
              </label>
              <input
                type="text"
                className="form-control"
                id="deviceName"
                value={addDevice.deviceName}
                onChange={changeNewDevice}
              />
              <label htmlFor="deviceNumber" className="col-form-label">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="deviceNumber"
                placeholder="+120812345678"
                value={addDevice.deviceNumber}
                onChange={changeNewDevice}
              />
            </div>
          )}
          {deviceAdvanced && (
            <div>
              <div className="form-item custom-control custom-switch mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={cellPhoneVoicemail === false}
                  id="cellPhoneVoicemail"
                  onChange={() => setCellPhoneVoicemail(!cellPhoneVoicemail)}
                />
                <label className="custom-control-label" htmlFor="cellPhoneVoicemail">
                  Allow use of cellphone's voicemail
                </label>
              </div>
              <div className="form-item custom-control custom-switch mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={keepCallerID === true}
                  id="originalCallID"
                  onChange={() => setKeepCallerID(!keepCallerID)}
                />
                <label className="custom-control-label" htmlFor="originalCallID">
                  Keep Original Caller-ID
                </label>
              </div>
              <div className="form-item custom-control custom-switch mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={contactList === true}
                  id="contactList"
                  onChange={() => setContactList(!contactList)}
                />
                <label className="custom-control-label" htmlFor="contactList">
                  Hide from Contact List
                </label>
              </div>
            </div>
          )}
          <div className="device-footer">
            <div className="text-left mt-4">
              {cellPhoneModalState && (
                <Button color="danger" onClick={deleteCellPhone}>
                  <i className="fas fa-trash"></i> <span className="ml-2">Delete Cell Phone</span>
                </Button>
              )}
            </div>
            <div className="text-right mt-4">
              {cellPhoneModalState ? (
                <Button color="primary" onClick={editCellPhone}>
                  Edit
                </Button>
              ) : (
                <Button color="primary" onClick={addCellPhone}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Callforward
