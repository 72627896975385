import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Badge, Tooltip } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import ContactDetailsSave from '../Home/ContactDetailsSave'
import { getCallForward } from '../../actions/message.action'
import { getAllContacts } from '../../actions/message.action'
import { updateContact } from '../../actions/message.action'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import QuickCall from '../Voicemails/QuickCall'
import CSVReader from 'react-csv-reader'
import './contacts.css'
import UserHeader from '../Header/UserHeader'
import ContactDelete from '../Home/ContactDelete'
import DistributionMenu from './DistributionMenu'
import { getDistributionContactList } from '../../actions/distribution.action'
import FilterMenu from './FilterMenu'
import NewSms from '../CallHistory/NewSms'
import { useHistory } from 'react-router-dom'
import ContactImage from './ContactImage'

export const CustomTooltip = ({ children, content, target }) => {
  const [open, setOpen] = useState(false)
  const toggleTooltip = () => setOpen((prev) => !prev)
  return (
    <>
      <Tooltip isOpen={open} toggle={toggleTooltip} placement="bottom" target={target}>
        {content}
      </Tooltip>
      {children}
    </>
  )
}

function handleSortBy(data, sortBy) {
  let field = ''
  let isAscending = true

  switch (sortBy) {
    case 'name-asc':
      field = 'name'
      break
      case 'name-desc':
        field = 'name'
        isAscending = false
        break
      case 'email-asc':
        field = 'email'
        break
      case 'email-desc':
        field = 'email'
        isAscending = false
        break
      case 'company-asc':
        field = 'company'
        break
      case 'company-desc':
        field = 'company'
        isAscending = false
        break
      case 'number-asc':
        field = 'formattedNumber'
        break
      case 'number-desc':
      field = 'formattedNumber'
      isAscending = false
      break
    default:
      break
  }
  return data.sort((a, b) => {
    if (!isAscending) {
      ;[b, a] = [a, b]
    }
    return (a[field] ?? '').localeCompare(b[field] ?? '')
  })
}

const phoneNumberFormat = (number) => {
  if (number) {
    const phone_number = parsePhoneNumberFromString(number)
    if (!phone_number) {
      var cleaned = ('' + number).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    } else {
      const phoneNumber = phone_number.formatNational()
      return phoneNumber
    }
  } else return number
}

function filteredContacts(allPhoneNum, { data, filter }, sortBy) {
  const output = (filter ? data : allPhoneNum).map((el) => ({
    ...el,
    name: el.labelName || el.name,
    formattedNumber: phoneNumberFormat(el.phoneNumber),
  }))
  return handleSortBy(output, sortBy)
}

const Contacts = (props) => {
  const dispatch = useDispatch()
  const { styleMode, isAdmin, savedNumber } = useSelector((state) => state.message.numbers)
  const { loading, contacts } = useSelector((state) => state.message)
  const { distributionContacts, allList } = useSelector((state) => state.distribution)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [allPhoneNum, setAllPhoneNum] = useState([])
  const [selectedKey, setSelectedKey] = useState('')
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [showContactDialog, setshowContactDialog] = useState(false)
  const [memberInformation, setMemberIformation] = useState([])
  const [allContactId, setAllContactId] = useState([])
  const [filter, setFilter] = useState({ data: [], filter: false })
  const [sortBy, setSortBy] = useState('name-asc')
  const [addContactData, setAddContactData] = useState(false)
  const userId = localStorage.getItem('user_id')
  const filteredPhoneNum = useMemo(() => filteredContacts(allPhoneNum, filter, sortBy), [allPhoneNum, filter, sortBy])
  let contactID = contacts.contactID ? contacts.contactID : contacts._id
  let history = useHistory()

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }
  const handleSearchNumber = (e) => {
    setSelectedKey(e.target.value)
  }

  const searchNumber = () => {
    const contactList = distributionContacts.distribution_contact
    if (selectedKey !== '') {
      const numbers =
        distributionContacts.distribution_contact &&
        distributionContacts.distribution_contact.filter(
          (num) => num.phoneNumber && num.phoneNumber.indexOf(selectedKey) !== -1,
        )
      setAllPhoneNum(numbers)
    } else {
      setAllPhoneNum(contactList)
    }
  }

  const isContactDialog = (val, contact) => {
    setshowContactDialog(val)
    if (contact) {
      setMemberIformation(contact)
    }
  }

  const toggleContactDialog = (val) => {
    setshowContactDialog(val)
  }

  useEffect(() => {
    if (distributionContacts) {
      if (distributionContacts.distributionId) {
      }
      setAllPhoneNum(distributionContacts.distribution_contact)
      distributionContacts.distribution_contact &&
        distributionContacts.distribution_contact.map((val) => {
          if (!allContactId.includes(val._id)) {
            const list = allContactId.filter((data) => data === val._id)
            setAllContactId(list)
          }
          return null
        })
    }
    //eslint-disable-next-line
  }, [distributionContacts])

  useEffect(() => {
    if (!isAdmin) {
      props.history.push('/home')
    }
    //eslint-disable-next-line
  }, [isAdmin])

  useEffect(() => {
    dispatch(getAllContacts(userId))
    dispatch(getDistributionContactList())
    //eslint-disable-next-line
  }, [userId])

  const phoneNumFormat = (number) => {
    if (number) {
      const phoneNumber = number.toString().replace(/[^+\d]+/g, '')
      return phoneNumber
    } else {
      return number
    }
  }

  const handleForce = (data, fileInfo) => {
    // setCsvContacts(data)
    let numberList
    let contactNumber
    data &&
      data.length > 0 &&
      data.map((number) => {
        if (number.group_membership) {
          if (!number.phone_1___value) {
            contactNumber = number.phone_1___value
          } else {
            if (number.phone_1___value) {
              if (typeof number.phone_1___value == 'string' && number.phone_1___value.includes(':::')) {
                numberList = number.phone_1___value.split(' ::: ')
                contactNumber = numberList.shift()
              } else {
                contactNumber = phoneNumFormat(number.phone_1___value)
              }
            }
          }
          return dispatch(
            updateContact(
              number.name,
              number.organization_1___name,
              number.e_mail_1___value,
              number.address_1___street,
              number.address_1___extended_address,
              number.address_1___city,
              number.address_1___postal_code,
              number.address_1___region,
              contactNumber,
              numberList,
              null,
              contactID,
              userId,
            ),
          )
        } else {
          const name =
            number.first_name || number.last_name ? `${number.first_name || ''} ${number.last_name || ''}`.trim() : null
          let address_street
          let address_street2
          let city
          let postal_code
          let state
          let contactList = []
          if (number.home_street) {
            let r = number.home_address && number.home_address.split(',').shift()
            let ret = r && r.replace(number.home_street, '')
            let ret1 = ret && ret.replace(number.home_country, '')
            let ret2 = ret1 && ret1.replace(number.home_city, '')
            let ret3 = ret2 && ret2.replace(number.home_postal_code, '')
            let street2 = ret3 && ret3.replace(number.home_state, '')
            address_street = number.home_street
            address_street2 = street2
            city = number.home_city
            postal_code = number.home_postal_code
            state = number.home_state
          } else if (number.business_street) {
            let r = number.business_address && number.business_address.split(',').shift()
            let ret = r && r.replace(number.business_street, '')
            let ret1 = ret && ret.replace(number.business_country, '')
            let ret2 = ret1 && ret1.replace(number.business_city, '')
            let ret3 = ret2 && ret2.replace(number.business_postal_code, '')
            let street2 = ret3 && ret3.replace(number.business_state, '')
            address_street = number.business_street
            address_street2 = street2
            city = number.business_city
            postal_code = number.business_postal_code
            state = number.business_state
          } else if (number.other_street) {
            let r = number.other_address && number.other_address.split(',').shift()
            let ret = r && r.replace(number.other_street, '')
            let ret1 = ret && ret.replace(number.other_country, '')
            let ret2 = ret1 && ret1.replace(number.other_city, '')
            let ret3 = ret2 && ret2.replace(number.other_postal_code, '')
            let street2 = ret3 && ret3.replace(number.other_state, '')
            address_street = number.other_street
            address_street2 = street2
            city = number.other_city
            postal_code = number.other_postal_code
            state = number.other_state
          }
          if (number.home_phone || number.mobile_phone || number.business_phone || number.other_phone) {
            contactNumber =
              phoneNumFormat(number.home_phone) ||
              phoneNumFormat(number.mobile_phone) ||
              phoneNumFormat(number.business_phone) ||
              phoneNumFormat(number.other_phone)
          } else {
            contactNumber = ''
          }
          if (number.home_phone_2 || number.business_phone_2) {
            contactList[0] = number.home_phone_2 || number.business_phone_2
          }
          return dispatch(
            updateContact(
              name,
              number.company,
              number.e_mail_address,
              address_street,
              address_street2,
              city,
              postal_code,
              state,
              contactNumber,
              contactList,
              null,
              contactID,
              userId,
            ),
          )
        }
      })
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  }

  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setAllContactId([...allContactId, e.target.value])
    } else {
      const data = allContactId.filter((data) => data !== e.target.value)
      setAllContactId(data)
    }
  }

  const toggleDeleteDialog = (val) => {
    setshowDeleteDialog(val)
  }

  const isDeleteDialog = (val) => {
    setshowDeleteDialog(val)
  }

  const getNumberFormat = (number) => {
    if (number && number.includes('+1')) {
      const formatNumber = number.slice(2)
      return formatNumber
    } else return number
  }

  const checkDistributionContact = (number) => {
    if (number) {
      const setColor = []
      distributionContacts &&
        distributionContacts.distribution &&
        distributionContacts.distribution.filter((dist) => {
          if (dist.phoneNumber === number && dist.status === true) {
            allList.map((element) => {
              if (element._id === dist.distributionId) {
                setColor.push(element)
                return element
              }
              return element
            })
          }
          return null
        })
      if (setColor.length > 0) {
        const color = setColor.map((col) => col)
        return color
      } else return undefined
    }
  }

  const handleContactDetalis = (contact) => {
    if (contact) {
      setMemberIformation(contact)
      setTimeout(() => {
        history.push(`/contacts/${contact._id}`)
      }, 1000)
    }
  }

  const handleSort = (value) => {
    setSortBy(value)
  }

  return (
    <div className={styleMode}>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="numbers">
            <div className="numbers-btns">
              <div className="search-btns align-items-center">
                <input
                  type="text"
                  className="form-control search-input"
                  id="search"
                  placeholder="Search..."
                  onChange={handleSearchNumber}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      searchNumber()
                    }
                  }}
                />
                <div className="contact-search">
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <div className="contact-sideMenu">
                <FilterMenu onFilter={setFilter} />
                <DistributionMenu />
                <div className="addContactBtn">
                  <i
                    onClick={() => {
                      isContactDialog(true, filteredPhoneNum)
                      setAddContactData(true)
                    }}
                    className="fas fa-plus"
                  />
                </div>
                <CSVReader
                  label="Import CSV"
                  cssClass="csv-reader-input"
                  onFileLoaded={handleForce}
                  parserOptions={papaparseOptions}
                />

                <div className="disIconst">
                  <Button color="primary" disabled={allContactId.length === 0} onClick={() => isDeleteDialog(true)}>
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <table className="table table-striped mb-0 client-table mt-4">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'name-asc' ? 'name-desc' : 'name-asc')}
                    >
                      Name {sortBy === 'name-asc' && <i class="fas fa-arrow-up"></i>}
                      {sortBy === 'name-desc' && <i class="fas fa-arrow-down"></i>}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'company-asc' ? 'company-desc' : 'company-asc')}
                    >
                      Company {sortBy === 'company-asc' && <i class="fas fa-arrow-up"></i>}
                      {sortBy === 'company-desc' && <i class="fas fa-arrow-down"></i>}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'number-asc' ? 'number-desc' : 'number-asc')}
                    >
                      Number {sortBy === 'number-asc' && <i class="fas fa-arrow-up"></i>}
                      {sortBy === 'number-desc' && <i class="fas fa-arrow-down"></i>}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'email-asc' ? 'email-desc' : 'email-asc')}
                    >
                      Email {sortBy === 'email-asc' && <i class="fas fa-arrow-up"></i>}
                      {sortBy === 'email-desc' && <i class="fas fa-arrow-down"></i>}
                    </th>
                    <th>Distribution</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPhoneNum &&
                    filteredPhoneNum.length > 0 &&
                    filteredPhoneNum.map((number, index) => (
                      <tr key={number._id}>
                        <td className="td-center">
                          <input
                            type="checkbox"
                            className="custom-input"
                            id={number._id}
                            value={number._id}
                            checked={allContactId.includes(number._id)}
                            onChange={handleCheck}
                          />
                        </td>
                        <td className="td-center">
                          <ContactImage _id={number._id} profileUrl={number.profileUrl} name={number.name ?? ''} />
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.name}
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.company && number.company}
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.formattedNumber}
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.email && number.email}
                        </td>
                        <td className="td-center">
                          {checkDistributionContact(number.phoneNumber) !== undefined &&
                          checkDistributionContact(number.phoneNumber).length > 0 ? (
                            <div className="multiple-dist">
                              {checkDistributionContact(number.phoneNumber).map((i, index) => {
                                return (
                                  <span key={index}>
                                    <CustomTooltip target={`Tooltip-${i._id}`} content={i.name}>
                                      <Badge
                                        className="dist-badge"
                                        color="secondary"
                                        style={{ backgroundColor: `${i.color}` }}
                                        id={`Tooltip-${i._id}`}
                                      ></Badge>
                                    </CustomTooltip>
                                  </span>
                                )
                              })}
                            </div>
                          ) : (
                            checkDistributionContact(number.phoneNumber) !== undefined && (
                              <>
                                <CustomTooltip target={`Tooltip-${number._id}`} content={number.name}>
                                  <Badge
                                    style={{ backgroundColor: `${number.color}` }}
                                    className="dist-badge"
                                    id={`Tooltip-${number._id}`}
                                  ></Badge>
                                  {JSON.stringify(number)}
                                </CustomTooltip>
                              </>
                            )
                          )}
                        </td>
                        <td className="td-center contact-action">
                          {number.phoneNumber !== null && (
                            <>
                              <QuickCall
                                history={props.history}
                                fromNumber={savedNumber}
                                toNumber={number.phoneNumber}
                                contact={true}
                              />

                              <NewSms
                                history={props.history}
                                toNumber={getNumberFormat(number.phoneNumber)}
                                fromNumber={savedNumber}
                                contact={true}
                              />
                            </>
                          )}
                          <i
                            className="fas fa-edit phone-icon"
                            onClick={() => {
                              isContactDialog(true, number)
                              setAddContactData(false)
                            }}
                          ></i>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {showContactDialog && (
                <ContactDetailsSave
                  isContactDialog={isContactDialog}
                  showContactDialog={showContactDialog}
                  toggleContactDialog={toggleContactDialog}
                  memberInformation={memberInformation}
                  distribution={distributionContacts.distribution}
                  contacts={distributionContacts.contacts}
                  addContactData={addContactData}
                />
              )}
              {showDeleteDialog && (
                <ContactDelete
                  isContactDialog={isDeleteDialog}
                  showDeleteDialog={showDeleteDialog}
                  toggleDeleteDialog={toggleDeleteDialog}
                  contact_id={allContactId}
                  toggleContactDialog={toggleContactDialog}
                  showContactDialog={showContactDialog}
                  distribution={distributionContacts.distribution}
                  contacts={distributionContacts.contacts}
                  // onDelete={props.onDelete}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
