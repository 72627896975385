import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { saveCallNotes, updateCallNotes, getCallFlow } from '../../actions/callhistory.action'
import './History.css'

const AddNotes = (props) => {
  const { interaction_id, fromNumber, toNumber, isOutboundCall, canPrivate } = props
  const day = new Date()
  const [notesToggle, updateNotesToggle] = useState(false)
  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const [startDate] = useState(new Date(day.setDate(day.getDate() - 7)))
  const [endDate] = useState(new Date())

  const [saveNotes, setSaveNotes] = useState([])
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1)
  const dispatch = useDispatch()
  const { all_notes } = useSelector((state) => state.callhistory)
  const [values, updateValues] = useState('')
  const [updatedAt, setUpdatedAt] = useState('')
  const [updatedBy, setUpdatedBy] = useState('')
  const { styleMode } = useSelector((state) => state.message.numbers)
  const email = localStorage.getItem('email')
  const [isPrivate, setPrivate] = useState(undefined)

  useEffect(() => {
    if (props?.call?.isPrivate !== undefined && props?.call?.isPrivate !== null) {
      setPrivate(!!props?.call?.isPrivate)
    }
  }, [props.call])

  useEffect(() => {
    let filter_ids = []
    if (all_notes.length > 0) {
      filter_ids = all_notes.map((ids) => ids.interaction_id === interaction_id)
      all_notes.map((ids) => {
        if (ids.interaction_id === interaction_id) {
          if (filter_ids.includes(true)) {
            updateValues(ids.notes)
            setUpdatedAt(ids.updatedAt)
            setUpdatedBy(ids.updated_by)
          }
        }
        return null
      })

      setSaveNotes(filter_ids)
    }
    // eslint-disable-next-line
  }, [all_notes, updateValues])

  const convertDateTime = (time) => {
    const date = new Date(time)
    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let year = date.getFullYear()
    let month = monthArray[date.getMonth()]
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()

    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    const strTime = month + ' ' + day + ', ' + year + '  at ' + hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  const notesModal = () => {
    updateNotesToggle(!notesToggle)
  }

  const handleValues = (e) => {
    updateValues(e.target.value)
  }

  const SaveNotes = async () => {
    if (values === '') {
      toast.warn('Please input notes.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      dispatch(
        saveCallNotes(
          values,
          interaction_id,
          email,
          props.call,
          isOutboundCall ? fromNumber : toNumber,
          isOutboundCall ? 'OUTBOUND' : 'INBOUND',
          isPrivate,
        ),
      )
      dispatch(getCallFlow(startDate, endDate))
      notesModal()
    }
  }

  const updateNotes = async () => {
    if (values === '') {
      toast.warn('Please input notes.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      dispatch(
        updateCallNotes(
          values,
          interaction_id,
          email,
          props.call,
          isOutboundCall ? fromNumber : toNumber,
          isOutboundCall ? 'OUTBOUND' : 'INBOUND',
          isPrivate,
        ),
      )
      dispatch(getCallFlow(startDate, endDate))
      notesModal()
    }
  }

  return (
    <>
      <div className="btn btn-light" id="newNotes" onClick={notesModal}>
        {saveNotes.includes(true) ? <i className="fas fa-sticky-note"></i> : <i className="far fa-sticky-note"></i>}
      </div>
      <Tooltip placement="top" isOpen={tooltipOpen1} autohide={false} target="newNotes" toggle={toggleTooltip1}>
        Call Notes
      </Tooltip>
      <Modal
        isOpen={notesToggle}
        toggle={notesModal}
        className={`${styleMode}-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ToastContainer autoClose={8000} />
        <ModalHeader toggle={notesModal}>
          <i className="far fa-sticky-note"></i>
          {saveNotes.includes(true) ? 'Update Call Notes' : 'Add Call Notes'}
        </ModalHeader>
        <ModalBody>
          <div className="contact">
            <div className="input-group mt-3 new-conversation">
              <textarea
                type="text"
                name="notes"
                className="form-control pl-4 notes-text"
                placeholder="Notes:"
                onChange={handleValues}
                defaultValue={saveNotes.includes(true) ? values : ''}
              />
              <div>
                <p className="notes-history">
                  {saveNotes.includes(true) && updatedBy !== ''
                    ? `Last Updated ${convertDateTime(updatedAt)} by ${updatedBy}`
                    : ''}
                </p>
              </div>
            </div>
          </div>
          {canPrivate && (
            <div>
              <input type="checkbox" id="canPrivate" checked={isPrivate} onClick={() => setPrivate((prev) => !prev)} />
              <label className="privateKeep" for="canPrivate">
                Keep Private
              </label>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {saveNotes.includes(true) ? (
            <Button color="primary" onClick={updateNotes}>
              Update
            </Button>
          ) : (
            <Button color="primary" onClick={SaveNotes}>
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddNotes
