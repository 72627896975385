import axios from 'axios'
import { logOut } from './auth.action'
import CONFIG from '../constants/config'
import * as CONSTS from '../constants/const'

export const saveNewAutomation = (data) => {
  return async (dispatch, getState) => {
    const { message } = getState()
    const { numberList } = message.numbers
    await axios
      .post(`${CONFIG.serverURL}/saveautomation`, {
        data,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getAutomations(numberList))
      })
  }
}
export const editAutomationData = (id, data) => {
  return async (dispatch, getState) => {
    const { message } = getState()
    const { numberList } = message.numbers
    await axios
      .post(`${CONFIG.serverURL}/editautomation`, {
        id: id,
        data,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getAutomations(numberList))
      })
  }
}
export const getAutomations = (data) => {
  return async (dispatch) => {
    const numberList = []
    data.forEach((num) => {
      numberList.push({ phone_number: num.number })
    })
    await axios.post(`${CONFIG.serverURL}/getautomations`, { data: numberList }).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: CONSTS.GET_AUTOMATIONS,
          payload: res.data,
        })
      }
    })
  }
}
export const deleteAutomation = (id) => {
  return async (dispatch, getState) => {
    const { message } = getState()
    const { numberList } = message.numbers
    await axios
      .post(`${CONFIG.serverURL}/deleteautomation`, {
        id: id,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getAutomations(numberList))
      })
  }
}
export const getPreFlow = () => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    const accounts = `${CONFIG.API_URL}/accounts/${account_id}`
    await axios
      .get(accounts)
      .then((res) => {
        dispatch({ type: CONSTS.GET_PREFLOW, payload: res.data.data.preflow })
      })
      .catch((error) => {
        if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && error.response.status === 401) {
          dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: 'Auth_Failed' })
          dispatch(logOut())
        }
      })
  }
}
export const saveCallFlowData = () => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    const callflows = `${CONFIG.API_URL}/accounts/${account_id}/callflows`
    const data = {
      contact_list: { exclude: false },
      ui_is_main_number_cf: false,
      name: 'Missed Call Alert',
      flow: {
        children: {},
        data: { name: '', recipients: [{ type: 'email', id: '' }] },
        module: 'missed_call_alert',
      },
      numbers: ['missedcall'],
    }
    await axios
      .put(callflows, { data: data })
      .then((res) => {
        dispatch(savePreFlow())
      })
      .catch((error) => {
        dispatch(savePreFlow())
      })
  }
}
export const savePreFlow = () => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    const callflows = `${CONFIG.API_URL}/accounts/${account_id}/callflows?filter_not_ui_metadata.origin=voip&filter_not_ui_metadata.origin=callqueues&paginate=false`
    await axios
      .get(callflows)
      .then(async (res) => {
        const resultData = res.data.data.filter(
          (flow) => flow.name === 'Missed Call Alert' && flow.modules[0] === 'missed_call_alert',
        )
        const flowId = resultData[0].id
        const account = `${CONFIG.API_URL}/accounts/${account_id}`
        const data = flowId === '' ? {} : { always: flowId }
        await axios
          .get(account)
          .then((res) => {
            const flowData = res.data.data
            flowData.preflow = data
            axios.post(account, { data: flowData }).then((res) => {
              dispatch({
                type: CONSTS.SAVE_CALL_FLOW,
                payload: { state: true, message: 'Successfully saved' },
              })
              dispatch(getPreFlow())
            })
          })
          .catch((error) => {
            if (
              typeof error !== 'undefined' &&
              typeof error.response !== 'undefined' &&
              error.response.status === 401
            ) {
            }
          })
      })
      .catch((error) => {
        if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && error.response.status === 401) {
        }
      })
  }
}
